import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { cookie } from '@ifeng-fe/ui_base';
import { getQrCode, getReadNumNormal, getPCUnlockArticle } from '@src/services/api';
import { wapactPageCount } from '@ifeng-fe/three_terminal/es/utils';

class CutOff extends React.PureComponent {
    static propTypes = {
        cutofffConfig: PropTypes.object,
        setCutoffHeight: PropTypes.func,
        articleBox: PropTypes.object,
    };

    componentDidMount() {
        if (this.isStopCutOff()) {
            return false;
        }
        this.goCutoffHandle();
    }

    state = {
        isShowCutoffTip: false,
        isShowQrCodeFix: false,
        qrcodeImage: '',
        qrcodeImagePop: '',
        readNum: 0,
    };

    isStopCutOff = () => {
        const { cutofffConfig } = this.props;
        const UA = navigator.userAgent.toLocaleLowerCase();
            
        if (cutofffConfig?.filterUA?.length > 0) {
            if (!cutofffConfig.filterUA.every(item => !UA.includes(item.uaKeyword))) {
                return true;
            }
        }

        return false;
    }

    getReadNumHandle = async () => {
        const { cutofffConfig } = this.props;
        let publishTime = cutofffConfig?.createTime?.replace(/-/g, '/');

        publishTime = new Date(publishTime).getTime() || new Date().getTime();
        publishTime = Math.floor(publishTime / 1000);
        const readNum = await getReadNumNormal(cutofffConfig?.base62Id, publishTime);

        this.setState({
            readNum,
        });
    };

    getUnlockPCHandle = () => {
        let unlockPcTimer = null;

        unlockPcTimer = setInterval(async () => {
            const isUnlock = await getPCUnlockArticle(cookie.get('userid'));

            if (isUnlock) {
                cookie.set(
                    'unlockPCCutoff',
                    '1',
                    '',
                    '',
                    new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - new Date().getTime(),
                );

                window.location.reload();
                clearInterval(unlockPcTimer);
            }
        }, 5000);
    };

    getPlanGray = () => {
        // 1是方案1
        // const planGrayCookie = cookie.get('planCutoffGray') || '';
        // const planGray = Math.floor(Math.random() * 2) + 1;
        // let plan = 'a';

        // if (planGrayCookie) {
        //     plan = planGrayCookie;
        // } else {
        //     plan = planGray === 1 ? 'a' : 'b';
        //     cookie.set(
        //         'planCutoffGray',
        //         plan,
        //         '',
        //         '',
        //         new Date(new Date().toLocaleDateString()).getTime() + 24 * 30 * 60 * 60 * 1000 - new Date().getTime(),
        //     );
        // }

        // return plan;
        return 'a';
    };

    goCutoffHandle = async () => {
        const { cutofffConfig, setCutoffHeight, articleBox } = this.props;
        const unlockPCCutoff = cookie.get('unlockPCCutoff');
        const unlockPCCutoffSucess = cookie.get('unlockPCCutoffSucess');
        let cutoffGrayNum = cookie.get('cutoffGrayNum');

        if (unlockPCCutoffSucess) {
            return false;
        }

        if (unlockPCCutoff) {
            if (this.getPlanGray() === 'b') {
                wapactPageCount({
                    value: 'pc_seeall_pop_y_b',
                    method: 'get',
                });
            } else {
                wapactPageCount({
                    value: 'pc_seeall_pop_y',
                    method: 'get',
                });
            }

            cookie.set(
                'unlockPCCutoffSucess',
                '1',
                '',
                '',
                new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - new Date().getTime(),
            );

            return false;
        }

        if (!cutoffGrayNum) {
            cutoffGrayNum = Math.floor(Math.random() * 100) + 1;

            // 设置命中用户cookie
            cookie.set(
                'cutoffGrayNum',
                cutoffGrayNum,
                '',
                '',
                new Date(new Date().toLocaleDateString()).getTime() + 24 * 30 * 60 * 60 * 1000 - new Date().getTime(),
            );
        }

        if (!cutofffConfig?.isCutoff || parseInt(cutoffGrayNum) > cutofffConfig?.cutoffGray || !cutofffConfig?.cutoffGray) {
            return false;
        }

        const boxDom = articleBox.current;
        const boxHeight = boxDom.offsetHeight;
        const boxRectTop = boxDom.getBoundingClientRect().top;
        const boxCutoffHeight = boxHeight / 10;
        const pDom = boxDom.getElementsByTagName('p');

        // 遍历p标签，获取p标签距离boxHeight容器的高度
        for (let i = 0; i < pDom.length; i++) {
            if (pDom?.[i] && pDom.length > 1) {
                const pDomItem = pDom[i];
                const pRectTop = pDomItem.getBoundingClientRect().top - boxRectTop;

                if (pRectTop > boxCutoffHeight) {
                    // 该标签添加class名
                    pDomItem.classList.add('ptagdel');
                }
            }
        }
        const ptagdel = boxDom.getElementsByClassName('ptagdel');
        const ptagdelLength = ptagdel.length;

        // 删除所有class名为ptagdel的标签
        for (let j = 0; j < ptagdelLength; j++) {
            const pdelTagItem = ptagdel?.[0];

            if (pdelTagItem?.parentNode) {
                // 通过父节点调用 removeChild() 方法删除当前元素
                pdelTagItem.parentNode.removeChild(pdelTagItem);
            }
        }

        const guideUrl =
            location.href.includes('172.30') || location.href.includes('test0.web-pages-content')
                ? `http://test0.web-pages-content.shank.ifeng.com/mobile/article/${cutofffConfig?.base62Id}`
                : location.href.split('?')?.[0];
        const userid = cookie.get('userid');

        setCutoffHeight(boxCutoffHeight);
        this.setState({
            isShowCutoffTip: true,
        });
        const qrcodeResult = await getQrCode(`${guideUrl}?userid=${userid}&plan=normal`);
        const qrcodePopResult = await getQrCode(`${guideUrl}?userid=${userid}&plan=${this.getPlanGray()}`);

        this.setState({
            qrcodeImage: qrcodeResult?.qrcode_url,
            qrcodeImagePop: qrcodePopResult?.qrcode_url,
        });
        this.getUnlockPCHandle();
        this.getReadNumHandle();
        wapactPageCount({
            value: 'pc_dl_page',
            method: 'get',
        });
    };

    setQrCodeFix = (value) => {
        this.setState({
            isShowQrCodeFix: value,
        });  
        if (value) {
            if (this.getPlanGray() === 'b') {
                wapactPageCount({
                    value: 'pc_seeall_b',
                    method: 'get',
                });
            } else {
                wapactPageCount({
                    value: 'pc_seeall',
                    method: 'get',
                });
            }
        }
    };

    render() {
        const { isShowCutoffTip, isShowQrCodeFix, qrcodeImage, qrcodeImagePop, readNum } = this.state;

        return (
            <>
                {isShowCutoffTip && (
                    <div
                        className={styles.articleCutoff}
                        onClick={() => {
                            this.setQrCodeFix(true);
                        }}>
                        <div className={styles.articleShadow}>
                            {readNum ? (
                                <div className={styles.articleShadowText}>
                                    已有<span className={styles.articleShadowTextRed}>{readNum}</span>人解锁该文章
                                </div>
                            ) : null}
                        </div>
                        <div className={styles.articleCutoffTip} />
                            {/* <div className={styles.articleCutoffQrcode}>
                                <img src={qrcodeImage} className={styles.articleCutoffQrcodePic} />
                            </div> */}
                        {/* </div> */}
                    </div>
                )}
                {isShowQrCodeFix && (
                    <div className={styles.qrCodeFix}>
                        <div className={styles.qrCodeFixBox}>
                            <div
                                className={styles.qrCodeClose}
                                onClick={() => {
                                    this.setQrCodeFix(false);
                                }}
                            />
                            <div className={styles.qrCodeFixTitle}>扫码10秒解锁文章，免费得VIP</div>
                            <div className={styles.qrCodeFixImage}>
                                <img src={qrcodeImagePop} className={styles.qrCodeFixPic} />
                                <div className={styles.qrCodeFixImageTip}>扫描二维码</div>
                            </div>
                            <div className={styles.qrCodeFixInfo}>
                                请使用<span className={styles.qrCodeTextRed}>微信</span>或<span className={styles.qrCodeTextRed}>原生相机</span>扫码即可
                                <br />
                                还有免费VIP、免广告、一键九鼎等多项特权打开就送
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default errorBoundary(CutOff);
