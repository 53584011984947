import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Vote from '../vote';
import styles from './index.css';
import { ContentShare } from '@ifeng-fe/ui_pc_share';

class Interact extends React.PureComponent {
    static propTypes = {
        keywords: PropTypes.string,
        voteId: PropTypes.string,
        shareInfo: PropTypes.object,
        content: PropTypes.object,
        isHubeiLocal: PropTypes.bool,
    };

    render() {
        const { keywords, voteId, shareInfo, content, isHubeiLocal } = this.props;
        const { isCloseShare } = content;

        return (
            <>
                <Vote keywords={keywords} voteId={voteId} content={content} isHubeiLocal={isHubeiLocal} />
                {!isCloseShare && (
                    <div className={styles.share_box}>
                        <ContentShare shareInfo={shareInfo} />
                    </div>
                )}
            </>
        );
    }
}

export default errorBoundary(Interact);
