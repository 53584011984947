import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { LazyImg } from '@ifeng-fe/ui_pc_lazyLoad';
import { getWirelessData } from '../../../../../../services/api';
import { isIE } from '../../../../../../utils/utils';
import AsideTitle from '../../../../components/asideTitle';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Wireless extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        observer: PropTypes.object,
    };

    container = React.createRef();

    state = {
        data: [],
    };

    async componentDidMount() {
        try {
            const data = await getWirelessData();

            this.setState({ data }, () => {
                const { observer } = this.props;

                Array.from(this.container.current.querySelectorAll('[data-img]')).forEach(item => {
                    observer.observe(item);
                });
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { data } = this.state;
        const { content } = this.props;

        return (
            <div className={styles.wireless}>
                {data && data.length > 0 ? (
                    <AsideTitle title={content.title}>
                        <span className={styles.link}>
                            {content.link.map((item, index) => (
                                <a key={index} href={item.url} target="_blank">
                                    {item.title}
                                </a>
                            ))}
                        </span>
                    </AsideTitle>
                ) : (
                    ''
                )}
                <ul className={styles.list} ref={this.container}>
                    {data.map(item => (
                        <li key={item.id}>
                            <a href={item.link_url} target="_blank">
                                <span
                                    style={{ background: isIE(7) || isIE(8) ? `url(${item.image_url})` : '' }}
                                    data-img={item.image_url}
                                />
                            </a>
                            <p>
                                <a href={item.link_url} target="_blank">
                                    {item.title}
                                </a>
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(Wireless);
