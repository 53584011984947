import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { Event } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Info from './info';
import { handleAd } from '@ifeng-fe/ui_pc_ad';

const event = new Event();

class Recommend extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array,
        ad: PropTypes.object,
        observer: PropTypes.object,
        base62Id: PropTypes.string,
    };

    container = React.createRef();

    async componentDidMount() {
        const { observer } = this.props;

        Array.from(this.container.current.querySelectorAll('[data-img]')).forEach(item => {
            observer.observe(item);
        });

        try {
            const { ad } = this.props;
            const callback = await handleAd(ad);

            callback(ad.data, event);

            event.trigger('init', { container: this.container.current });
        } catch (e) {
            console.error(e);
        }
    }

    getInfoList = () => {
        const { data } = this.props;
        const info = data.map(item => <Info key={item.id} data={item} count={item.count} />);

        info.push(
            <div key="noMore" className={styles.no_more}>
                已显示全部内容
            </div>,
        );

        return info;
    };

    render() {
        const { base62Id } = this.props;

        return (
            <div className={styles.recommend}>
                <div className={styles.title}>
                    <h3 className={styles.titleText}>为您推荐</h3>
                    <a
                        href={`https://client.ifeng.com/report/artical?docid=${base62Id}`}
                        className={styles.reportLink}
                        target="_blank">
                        算法反馈
                    </a>
                </div>
                <div className={styles.list_box} ref={this.container}>
                    {this.getInfoList()}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Recommend);
