import { formatImageWebpAuto } from 'ThreeTerminal_base/publicMethod';

const width1x = 375;
const width3x = width1x * 3;

export const formatWebpUrl = url => {
    // 如果是gif，不走动态裁图
    const gifReg = /\.gif$/;

    if (gifReg.test(url)) {
        return url;
    }

    const reg = /^(https?:)?\/\/\w+?.ifengimg.com\/.+?_w(\d+)_h(\d+)\.\w+?$/i;
    const capture = url.match(reg);

    let _url = url;

    if (capture) {
        const src = capture[0];
        let width = Number(capture[2]);
        let height = Number(capture[3]);

        if (!isNaN(width) && !isNaN(height)) {
            // if (width > width3x) {
            //     height = Math.floor((height * width3x) / width);
            //     width = width3x;
            // }
            
            if (width > 10000 || height > 10000) {
                _url = url;
            } else {
                // 将height设置为0，此时动态裁图接口自动根据宽度计算高度，如出现问题可以替换为上边注释的逻辑（前端计算高度）
                if (width > width3x) {
                    height = 0;
                    width = width3x;
                }

                _url = formatImageWebpAuto(src, width, height);
            }
        }
    }

    return _url;
};
