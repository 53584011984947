import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { isIE } from '../../../../../../utils/utils';

class Goodbook extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        title: PropTypes.string,
        observer: PropTypes.object,
    };

    container = React.createRef();

    componentDidMount() {
        const { observer } = this.props;

        Array.from(this.container.current.querySelectorAll('[data-img]')).forEach(item => {
            observer.observe(item);
        });
    }

    render() {
        const { content, title } = this.props;

        if (!content || content.length === 0) return <div />;

        return (
            <div className={styles.box}>
                <div className={styles.title}>
                    <h3>
                        <i>{title}</i>
                    </h3>
                </div>
                <ul className="clearfix" ref={this.container}>
                    {content.map((item, index) => (
                        <li key={index} className="clearfix">
                            <a href={item.url} target="_blank" title={item.title}>
                                {item.img && item.img !== '' ? (
                                    <span
                                        style={{ background: isIE(7) || isIE(8) ? `url(${item.img})` : '' }}
                                        data-img={item.img}
                                    />
                                ) : (
                                    ''
                                )}
                                <p>{item.title}</p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(Goodbook);
