import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import { Share } from '@ifeng-fe/ui_pc_share';
import errorBoundary from '@ifeng-fe/errorBoundary';

class SelectShare extends React.PureComponent {
    static propTypes = {
        styleObj: PropTypes.object,
        shareInfo: PropTypes.object,
    };

    render() {
        const { styleObj, shareInfo } = this.props;

        const shareConfig = {
            items: ['sina', 'qqzone'],
            display: 'filling',
            hover: 'filling',
            type: 'horizontal',
            methods: 'click',
            tooltip: 'top',
        };

        const selectShare = (
            <div className={styles.select_share} style={styleObj}>
                <div className={styles.header}>分享到：</div>
                <div className={styles.share_box}>
                    <Share config={shareConfig} shareInfo={shareInfo} />
                </div>
            </div>
        );

        return ReactDOM.createPortal(selectShare, document.body);
    }
}

export default errorBoundary(SelectShare);
