import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Affix extends React.PureComponent {
    static propTypes = {
        allowComment: PropTypes.bool,
        content: PropTypes.object,
    };

    state = {
        isShow: false,
        helpUrl: 'https://help.ifeng.com',
    };

    componentDidMount() {
        const {
            content: { base62Id },
        } = this.props;

        this.unHandleSrcoll = addEventListener(window, 'scroll', this.handleScroll);
        const ishareUrl = `https://ishare.ifeng.com/c/s/${base62Id}`;

        this.setState({
            helpUrl: `${this.state.helpUrl}?url=${encodeURIComponent(ishareUrl)}`,
        });
    }

    componentWillUnmount() {
        this.unHandleSrcoll();
    }

    handleComment = () => {
        const {
            content: { base62Id },
        } = this.props;

        if (window) window.open(`//gentie.ifeng.com/c/comment/${base62Id}`);
    };

    /**
     * 滚动条滚动
     */
    handleScroll = () => {
        // 兼容各主流浏览器
        const currentTop =
            document.documentElement.scrollTop || (window && window.pageYOffset) || document.body.scrollTop;

        if (currentTop > 100) {
            this.setState({ isShow: true });
        } else {
            this.setState({ isShow: false });
        }
    };

    /**
     * 回到顶部
     */
    backToTop = () => {
        scrollTo(0, 0);
    };

    /**
     * 渲染组件
     */
    render() {
        const { isShow, helpUrl } = this.state;
        const {
            allowComment,
            content: { floatAd1, floatAd2, base62Id },
        } = this.props;

        const affix = (
            <React.Fragment>
                <div className={styles.affix_box}>
                    <div className={styles.bottom_affix}>
                        <a className={styles.backToHome} href="//www.ifeng.com/" />
                        {allowComment ? <span className={styles.comment} onClick={this.handleComment} /> : ''}
                        <a className={styles.feedback} href={helpUrl} target="_blank" />
                        {isShow ? <span className={styles.backToTop} onClick={this.backToTop} /> : ''}
                    </div>
                </div>
                <Ad content={floatAd1} styleName={styles.floatAd} />
                <Ad content={floatAd2} styleName={styles.floatAd} />
            </React.Fragment>
        );

        return ReactDOM.createPortal(affix, document.body);
    }
}

export default errorBoundary(Affix);
