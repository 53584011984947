import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { vote, getVoteNum } from '@ifeng-fe/container_content/common/client/services/api';
import { getLocalWeigthVoteNum } from '@src/services/api';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Vote extends React.PureComponent {
    static propTypes = {
        voteId: PropTypes.string,
        keywords: PropTypes.string,
        content: PropTypes.object,
        isHubeiLocal: PropTypes.bool,
    };

    state = {
        isVoted: false,
        voteNum: 0,
    };

    async componentDidMount() {
        try {
            const { voteId, isHubeiLocal } = this.props;
            let data = {};

            if (isHubeiLocal) {
                data = await getLocalWeigthVoteNum(`${voteId}ding`);
            } else {
                data = await getVoteNum(`${voteId}ding`);
            }

            this.setState({ voteNum: data.browse[`${voteId}ding`] });
        } catch (e) {
            console.error(e);
        }
    }

    vote = async () => {
        try {
            const { isVoted, voteNum } = this.state;

            if (isVoted) {
                return;
            }

            const { voteId } = this.props;

            const data = await vote(`${voteId}ding`);

            this.setState({
                isVoted: !isVoted,
                voteNum: voteNum + 1,
            });
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { isVoted, voteNum } = this.state;
        const { keywords, content } = this.props;
        const { isCloseLike, isOpenCandle, isOpenpray } = content;

        // console.log(isCloseLike, isOpenCandle, isOpenpray, !isCloseLike && !isOpenCandle && !isOpenpray, '3333333');

        return (
            <>
                {!isCloseLike && !isOpenCandle && !isOpenpray && (
                    <div className={isVoted ? styles.voted : styles.vote} onClick={this.vote}>
                        推荐<span>{voteNum}</span>
                    </div>
                )}
                {isCloseLike && isOpenCandle && (
                    <div className={isVoted ? styles.voted1 : styles.vote1} onClick={this.vote}>
                        蜡烛<span>{voteNum}</span>
                    </div>
                )}
                {isCloseLike && isOpenpray && (
                    <div className={isVoted ? styles.voted2 : styles.vote2} onClick={this.vote}>
                        祈祷<span>{voteNum}</span>
                    </div>
                )}
            </>
        );
    }
}

export default errorBoundary(Vote);
