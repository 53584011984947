import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import AsideTitle from '../../../components/asideTitle';
import Request from './request';
import Wexin from './weixin';
import Wexins from './weixins';
import Welfare from './welfare';
import Wireless from './wireless';
import GoodbookList from './goodbookList';
import WutonghuiPic from './wutonghuiPic';
import WutonghuiContent from './wutonghuiContent';
import Assess from './assess';

class RightContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        observer: PropTypes.object,
    };

    render() {
        const { content, observer } = this.props;
        const {
            searchPath,
            article,
            video,
            qrCodeSet,
            welfare,
            fhhQrcode,
            wireless,
            goodbook1,
            articleAd,
            videoAd,
            asideAd1,
            asideAd2,
            asideAd3,
            asideAd4,
            asideAd5,
            asideAd6,
            wutonghuiPic,
            wutonghuiTab,
            wutonghuiContent,
            isHitBlack,
            assess,
        } = content;

        const articleRecommendInfo = {
            id: searchPath.indexOf('2-') === 0 || searchPath.indexOf('4-') === 0 ? '60020' : '30022',
            type: 'recommend',
            title: '热门文章推广位',
            groupName: '侧栏',
            content: article,
            position: 'relative',
            translate: 'handleArticleData',
        };

        const videoRecommendInfo = {
            id: searchPath.indexOf('16-') === 0 ? '60021' : '30023',
            type: 'recommend',
            title: '精彩视频推广位',
            groupName: '侧栏',
            content: video,
            position: 'relative',
            translate: searchPath.indexOf('16-') === 0 ? 'handleFoVideoData' : 'handleVideoData',
        };

        return (
            !isHitBlack && (
                <div className={styles.rightContent}>
                    <Ad content={asideAd1} styleName={styles.asideAd1} />
                    <div className={styles.space30} />
                    <Request
                        title="热门文章"
                        info={searchPath.indexOf('16-') === 0 ? null : articleRecommendInfo}
                        ad={articleAd}
                        observer={observer}
                        type="article"
                    />
                    <div className={styles.space40} />
                    <Ad content={asideAd2} styleName={styles.asideAd2} />
                    <div className={styles.space25} />
                    <Request
                        title={searchPath.indexOf('16-') === 0 ? '精彩推荐' : '精彩视频'}
                        isFo={searchPath.indexOf('16-') === 0}
                        info={videoRecommendInfo}
                        ad={videoAd}
                        observer={observer}
                        type="video"
                    />
                    <Assess content={assess} searchPath={searchPath || ''} />
                    <div className={styles.space40} />
                    <Ad content={asideAd3} styleName={styles.asideAd3} />
                    <div className={styles.space40} />
                    {qrCodeSet ? (
                        <Chip
                            id="30004"
                            type="struct"
                            title="微信二维码与微博"
                            groupName="侧栏"
                            position="relative"
                            content={qrCodeSet}>
                            {qrCodeSet instanceof Array ? <Wexins /> : <Wexin />}
                        </Chip>
                    ) : (
                        ''
                    )}
                    <div className={styles.space40} />
                    <Chip
                        id="30003"
                        type="struct"
                        title="扫码献爱心"
                        groupName="侧栏"
                        position="relative"
                        content={welfare}>
                        <Welfare />
                    </Chip>
                    <div className={styles.space40} />
                    <Chip
                        id="65002"
                        type="struct"
                        title="大风号二维码"
                        groupName="侧栏"
                        position="relative"
                        content={fhhQrcode}>
                        <Welfare />
                    </Chip>
                    {/* <div className={styles.space40} /> */}
                    {/* <Ad content={asideAd4} styleName={styles.asideAd4} /> */}
                    <div className={styles.space30} />
                    {searchPath.indexOf('16-') === 0 ? '' : <Wireless content={wireless} observer={observer} />}
                    <div className={styles.space30} />
                    {/* <Ad content={asideAd5} styleName={styles.asideAd5} />
                    <div className={styles.space40} /> */}
                    {/* <Ad content={asideAd6} styleName={styles.asideAd6} /> */}
                    {/* <div className={styles.space25} /> */}
                    {goodbook1 ? (
                        <Chip
                            id="55002"
                            type="recommend"
                            title="侧栏好书精选"
                            groupName="侧栏"
                            position="relative"
                            content={goodbook1}
                            translate="handleGoodbookData">
                            <GoodbookList observer={observer} />
                        </Chip>
                    ) : (
                        ''
                    )}
                    <div className={styles.space25} />
                    {wutonghuiPic ? (
                        <Chip id="170040" type="struct" title="梧桐汇推广" groupName="侧栏" content={wutonghuiPic}>
                            <WutonghuiPic observer={observer} />
                        </Chip>
                    ) : (
                        ''
                    )}
                    <div className={styles.space25} />
                    {wutonghuiTab ? (
                        <Chip id="200036" type="struct" title="梧桐汇标题" groupName="侧栏" content={wutonghuiTab}>
                            <AsideTitle />
                        </Chip>
                    ) : (
                        ''
                    )}
                    {wutonghuiContent ? (
                        <Chip
                            id="225034"
                            type="recommend"
                            title="梧桐汇内容"
                            groupName="侧栏"
                            content={wutonghuiContent}
                            translate="handleWutonghuiContent">
                            <WutonghuiContent observer={observer} />
                        </Chip>
                    ) : (
                        ''
                    )}
                </div>
            )
        );
    }
}

export default errorBoundary(RightContent);
