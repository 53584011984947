import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Loadable from 'react-loadable';
import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from 'ThreeTerminal_base/publicMethod';

const LoadWeibo = Loadable({
    loader: () => import('../weibo'),
    loading() {
        return <div />;
    },
});

class Wexin extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { qrCode, name, title, url, uid },
        } = this.props;

        return (
            <div className={styles.box}>
                <div className={styles.pic}>
                    <p>
                        <img src={formatImage(qrCode, 80, 80)} width="80" height="80" />
                    </p>
                    <p className={styles.text}>{name}</p>
                </div>
                <div className={styles.detail}>
                    <p>
                        <a href={url} target="_blank" title={title}>
                            {title}
                        </a>
                    </p>
                    <LazyLoad>
                        <LoadWeibo uid={uid} />
                    </LazyLoad>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Wexin);
