import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class Goods extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        pluginData: PropTypes.object,
    };

    getImageSize = (imageUrl) => {
        const regex = /w(\d+)_h(\d+)/;
        const matches = imageUrl.match(regex);

        if (matches) {
            return {
                width: Number(matches?.[1]),
                height: Number(matches?.[2])
            };
        } else {
            return null;
        }
    }

    render() {
        const { content, pluginData } = this.props;
        const { id = '' } = content;
        const { goodsPlugin = {} } = pluginData;
        const { goods = [] } = goodsPlugin;
        const goodsData = goods.filter(item => item.id === id)[0] || {};
        const pcPic = goodsData.pcPic || 'https://lm.yc.ifeng.com/shop/qrcode.png';

        return goodsData.id ? (
            <div>
                {goodsData.pcUrl ? (
                    <a href={goodsData.pcUrl} target="_blank">
                        <img
                            className={`miniapp ${this.getImageSize(pcPic)?.width >= 400 ? 'imgWidthFill' : ''}`}
                            src={pcPic}
                            data-pid={goodsData.goodId}
                            alt="凰家尚品微信小程序二维码"
                        />
                    </a>
                ) : (
                    <img className={`miniapp ${this.getImageSize(pcPic)?.width >= 400 ? 'imgWidthFill' : ''}`} src={pcPic} data-pid={goodsData.goodId} alt="凰家尚品微信小程序二维码" />
                )}
            </div>
        ) : null;
    }
}

export default Goods;
