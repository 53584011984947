import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { logo, domain, breadCrumbdata },
        } = this.props;

        return (
            <div className={styles.logo}>
                <a href={`//${domain}`} target="_blank">
                    <img src={logo.logo} alt={logo.title} />
                </a>
                <div className={styles.breadcrumbs}>
                    {breadCrumbdata.map((item, index) => (
                        <span key={index}>
                            <a href={item.url}>{item.title}</a>
                            {' > '}
                        </span>
                    ))}
                    <span>正文</span>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Logo);
