import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { getArticle, getVideo } from '../../../../../../services/api';
import errorBoundary from '@ifeng-fe/errorBoundary';
import HotList from '../../../../components/hotList';

class Request extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
        title: PropTypes.string,
        type: PropTypes.string,
        info: PropTypes.object,
        isFo: PropTypes.bool,
        observer: PropTypes.object,
    };

    state = {
        article: [],
        video: [],
    };

    async componentDidMount() {
        try {
            const { type, isFo } = this.props;

            if (type === 'article') {
                const article = await getArticle();

                this.setState({
                    article,
                });
            } else if (type === 'video' && !isFo) {
                const video = await getVideo();

                this.setState({
                    video,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { article, video } = this.state;
        const { title, ad, type, info, isFo, observer } = this.props;

        const data = type === 'article' ? article : video;

        if (isFo && info.content && info.content.length > 0) {
            return (
                <Chip {...info}>
                    <HotList title={title} data={[]} ad={ad} type={type} isFo={isFo} observer={observer} />
                </Chip>
            );
        }

        if (data && data.length > 0) {
            return info ? (
                <Chip {...info}>
                    <HotList title={title} data={data} ad={ad} type={type} observer={observer} />
                </Chip>
            ) : (
                <HotList title={title} content={[]} data={data} ad={ad} type={type} observer={observer} />
            );
        }

        return null;
    }
}

export default errorBoundary(Request);
