import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import '@ifeng-fe/ui_pc_lazyLoad/src/core/intersection-observer';
import SsrIntersectionObserver from '@ifeng-fe/ui_pc_lazyLoad/src/core/ssrIntersectionObserver';
import { formatWebpUrl } from '../../../../utils/formatWebp';

const RealIntersectionObserver =
    typeof IntersectionObserver !== 'undefined' ? IntersectionObserver : SsrIntersectionObserver;

class Text extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
        handleMouseUp: PropTypes.func,
        isWutonghui: PropTypes.bool
    };

    ref = React.createRef();

    createMarkup = () => {
        const { content } = this.props;

        return { __html: content };
    };

    getImageSize = (imageUrl) => {
        const regex = /w(\d+)_h(\d+)/;
        const matches = imageUrl.match(regex);

        if (matches) {
            return {
                width: Number(matches?.[1]),
                height: Number(matches?.[2])
            };
        } else {
            return null;
        }
    }

    componentDidMount() {
        const { isWutonghui } = this.props;

        if (!window.__IFENG_LOW__) {
            const observer = new RealIntersectionObserver(changes => {
                const changesFilter = changes.filter(item => item.isIntersecting > 0);

                changesFilter.forEach(change => {
                    const container = change.target;

                    container.onload = () => {
                        if (container.classList && container.classList.remove) {
                            container.classList.remove('empty_bg');
                        }

                        container.style.width = '';
                        container.style.height = '';
                    };

                    container.onerror = () => {
                        container.style.opacity = '';
                    };
                    const lazyloadImageUrl = container.getAttribute('data-lazyload');
                    const imageSize = this.getImageSize(lazyloadImageUrl);

                    if (isWutonghui && imageSize?.width >= 400) {
                        // 图片实际宽度超过400，进行图片宽度为100%
                        container.classList.add('imgWidthFill');
                    }

                    container.src = formatWebpUrl(lazyloadImageUrl);

                    observer.unobserve(container);
                });
            });

            Array.from(this.ref.current.getElementsByTagName('img'))
                .filter(item => {
                    return !!item.getAttribute('data-lazyload');
                })
                .forEach(item => {
                    observer.observe(item);
                });
        } else {
            Array.from(this.ref.current.getElementsByTagName('img'))
                .filter(item => {
                    return !!item.getAttribute('data-lazyload');
                })
                .forEach(item => {
                    const container = item;

                    container.onload = () => {
                        if (container.classList && container.classList.remove) {
                            container.classList.remove('empty_bg');
                        }

                        container.style.width = '';
                        container.style.height = '';
                    };

                    container.onerror = () => {
                        container.style.opacity = '';
                    };

                    container.src = formatWebpUrl(container.getAttribute('data-lazyload'));
                });
        }
    }

    render() {
        const { handleMouseUp } = this.props;

        return (
            <div
                className={styles.text}
                dangerouslySetInnerHTML={this.createMarkup()}
                onMouseUp={handleMouseUp}
                ref={this.ref}
            />
        );
    }
}

export default Text;
