import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { Event } from '@ifeng-fe/ui_base';
import ArticleContent from '../../../../../../components/articleContent';
import SelectShare from '../../../../../../components/selectShare';
import { handleAd } from '@ifeng-fe/ui_pc_ad';

const event = new Event();

class MainContent extends React.PureComponent {
    static propTypes = {
        topic: PropTypes.string,
        shareInfo: PropTypes.object,
        content: PropTypes.object,
        pluginData: PropTypes.object,
        ad: PropTypes.object,
        cutofffConfig: PropTypes.object,
    };

    state = {
        isShow: false,
        style: {},
        shareInfo: this.props.shareInfo,
    };

    container = React.createRef();

    async componentDidMount() {
        const { ad } = this.props;

        try {
            const callback = await handleAd(ad);

            callback(ad.data, event);

            event.trigger('init', { container: this.container.current });
        } catch (e) {
            console.error(e);
        }
    }

    handleSelectTxt = (event) => {
        const { shareInfo } = this.props;
        let text = '';

        try {
            text = document.selection.createRange().text;
        } catch (err) {
            text = window && window.getSelection().toString();
        }

        // 兼容 IE
        const e = event || (window && window.event);
        const scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
        const scrollY = document.documentElement.scrollTop || document.body.scrollTop;
        const left = e.pageX || e.clientX + scrollX;
        const top = e.pageY || e.clientY + scrollY;

        if (text !== '') {
            shareInfo.title = text;

            this.setState({
                isShow: true,
                style: {
                    top,
                    left,
                },
                shareInfo,
            });
        } else {
            this.setState({
                isShow: false,
            });
        }
    };

    render() {
        const { isShow, style, shareInfo } = this.state;
        const { topic, content, pluginData, cutofffConfig } = this.props;

        return (
            <div className={styles.main_content} ref={this.container}>
                {content.contentList ? (
                    <ArticleContent
                        topic={topic}
                        content={content}
                        pluginData={pluginData}
                        handleSelectTxt={this.handleSelectTxt}
                        cutofffConfig={cutofffConfig}
                    />
                ) : (
                    ''
                )}
                {isShow ? <SelectShare styleObj={style} shareInfo={shareInfo} /> : ''}
            </div>
        );
    }
}

export default errorBoundary(MainContent);
