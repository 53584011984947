import React from 'react';
import PropTypes from 'prop-types';
import { getRecommendInfo, getRelateFeedList } from '../../../../../../services/api';
import { formatImage } from 'ThreeTerminal_base/publicMethod';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from './recommend';

class Request extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
        observer: PropTypes.object,
        isHitBlack: PropTypes.bool,
        relatedRecommend: PropTypes.array,
        isCloseAlgRec: PropTypes.bool,
        base62Id: PropTypes.string,
    };

    state = {
        data: [],
    };

    formatRelateData = data => {
        let result = [];

        if (!data || data.length === 0) {
            return result;
        }
        result = data.map(item => {
            return {
                base62Id: item.base62Id,
                count: {},
                createtime: item.newsTime,
                pcUrl: item.url,
                url: item.url,
                title: item.title || '',
                source: item.source || '',
                sourceFrom: item.type || '',
                thumbnailpic:
                    (item.thumbnails &&
                        item.thumbnails.image &&
                        item.thumbnails.image[0] &&
                        item.thumbnails.image[0].url &&
                        formatImage(item.thumbnails.image[0].url, 140, 80)) ||
                    '',
            };
        });

        return result;
    };

    arrayPropertyUnique = (arr = [], uniId = '') => {
        const obj = {};
        const tempArr = [];

        for (let i = 0; i < arr.length; i++) {
            if (!obj[arr[i][uniId]]) {
                tempArr.push(arr[i]);
                if (arr[i][uniId]) {
                    obj[arr[i][uniId]] = true;
                }
            }
        }

        return tempArr;
    };

    async componentDidMount() {
        const { isHitBlack, relatedRecommend = [], isCloseAlgRec } = this.props;

        try {
            let relateData = [];

            if (relatedRecommend.length > 0) {
                relateData = await getRelateFeedList(relatedRecommend);
            }
            const recommendData = await getRecommendInfo();
            let resultData = [];

            relateData = this.formatRelateData(relateData);

            if (isHitBlack || isCloseAlgRec) {
                resultData = relateData;
            } else {
                resultData = relateData.concat(recommendData);
            }

            resultData = this.arrayPropertyUnique(resultData, 'base62Id');

            if (resultData && resultData.length > 0) {
                this.setState({
                    data: resultData,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { data } = this.state;
        const { ad, observer, base62Id } = this.props;

        if (data && data.length > 0) {
            return <Recommend data={data} ad={ad} observer={observer} base62Id={base62Id} />;
        }

        return null;
    }
}

export default errorBoundary(Request);
