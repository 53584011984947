import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { Event } from '@ifeng-fe/ui_base';
import AsideTitle from '../asideTitle';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { handleRecommendData, isIE } from '../../../../utils/utils';
import { handleAd } from '@ifeng-fe/ui_pc_ad';

class HotList extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.array,
        data: PropTypes.array,
        ad: PropTypes.object,
        type: PropTypes.string,
        isFo: PropTypes.bool,
        observer: PropTypes.object,
    };

    event = new Event();
    container = React.createRef();

    async componentDidMount() {
        const { ad, observer } = this.props;

        Array.from(this.container.current.querySelectorAll('[data-img]')).forEach(item => {
            observer.observe(item);
        });

        try {
            const callback = await handleAd(ad);

            callback(ad.data, this.event);

            this.event.trigger('init', { container: this.container.current });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { content, data, title, type, isFo } = this.props;
        const isVideo = type === 'video';

        const result = isFo ? content : handleRecommendData(content, data);

        return (
            <div className={styles.hotList}>
                <AsideTitle title={title} />
                <ul ref={this.container}>
                    {result.map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank">
                                {item.img !== '' ? (
                                    <span
                                        style={{ background: isIE(7) || isIE(8) ? `url(${item.img})` : '' }}
                                        data-img={item.img}
                                    />
                                ) : (
                                    ''
                                )}
                                {isVideo && !isFo && item.img !== '' ? <div className={styles.play_btn} /> : ''}
                                <p className={styles.title}>{item.title}</p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(HotList);
