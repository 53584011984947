import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Footer from '@ifeng-fe/container_content/common/client/components/footer';

class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { bottomAd, footer } = content;

        return (
            <div className={styles.bottomFooter}>
                <div className={styles.bottomAd_box}>
                    <Ad content={bottomAd} styleName={styles.bottomAd} />
                </div>
                <Footer content={footer} />
            </div>
        );
    }
}

export default errorBoundary(BottomFooter);
