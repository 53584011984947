import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { cookie } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
// import { Player } from 'ThreeTerminal_pc_dynamic';
import { Player } from 'ThreeTerminal_pc';
import { formatImageAblur } from '@ifeng-fe/public_method';

let autoPlay = true;

if (typeof document !== 'undefined' && cookie.get('autoplay') === 'no') {
    autoPlay = false;
}

let playermsg = {
    width: 480,
    height: 380,
    isAuth: 'auth',
    autoPlay,
    showAdBehind: true,
    adType: '1',
};

class Video extends React.PureComponent {
    static propTypes = {
        topic: PropTypes.string,
        videoinfo: PropTypes.object,
        pluginData: PropTypes.object,
    };

    state = {
        checked: autoPlay,
    };

    handleChange = () => {
        const { checked } = this.state;

        if (!checked) {
            cookie.set('autoplay', 'yes');
        } else {
            cookie.set('autoplay', 'no');
        }

        this.setState({
            checked: !checked,
        });
    };

    render() {
        const { checked } = this.state;
        const { topic, videoinfo, pluginData } = this.props;

        if (topic !== '') {
            playermsg = { ...playermsg, subject: topic };
        }

        videoinfo.bigPosterUrl = (videoinfo.bigPosterUrl && formatImageAblur(videoinfo.bigPosterUrl, 480, 380)) || '';

        return (
            <div
                className={styles.video_box}
                style={{
                    width: pluginData?.isMiddle ? 'auto' : '',
                    background: pluginData?.isMiddle ? 'none' : '',
                }}>
                <div className={styles.video}>
                    <Player videoConfig={videoinfo} playermsg={playermsg} />
                </div>
                {pluginData?.isMiddle ? null : (
                    <>
                        <input type="checkbox" checked={checked ? 'checked' : ''} onChange={this.handleChange} />
                        <span>自动播放</span>
                    </>
                )}
            </div>
        );
    }
}

export default errorBoundary(Video);
