import { jsonp, ajax } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from 'ThreeTerminal_base/publicMethod';
import { getUserid, getUserkey, getOs } from '../utils/utils';
import { getPlantform } from '../utils/nativeShare';
import axios from 'axios';

// apiUrl为webpack注入的全局变量
/* eslint-disable no-undef */
let apiBaseUrl = apiUrl;
/* eslint-enable no-undef */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/c';
}
const md5 = require('@ifeng-fe/md5');
const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    while (window[jsonpCallbackName]) {
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

// 获取热点文章数据
export const getRecommend = async (page, seo = false) => {
    const uid = getUserid();
    const os = getPlantform();
    const operation = 'pullUp';
    const size = 10;
    let data = null;

    try {
        data = await jsonp('//api.iclient.ifeng.com/ifengH5DocRelate', {
            data: {
                uid,
                os,
                operation,
                pageNum: page,
                size,
            },
            jsonpCallback: `getCommentCount_${page}`,
            cache: false,
        });

        if (seo) {
            for (const item of data) {
                try {
                    if (item.specialParam && item.specialParam.pcUrl && !item.specialParam.pcUrl.includes('auto.')) {
                        item.specialParam.pcUrl = item.specialParam.pcUrl.replace(
                            /\w+\.ifeng.com\/c\//,
                            'i.ifeng.com/c/',
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    return data;
};

// 获取手凤单页推荐内容
export const getRecommendArticle = async (searchPath, recommendDefault) => {
    let result = [...recommendDefault];

    if (searchPath) {
        const data = await jsonp(`${apiBaseUrl}/api/article/${searchPath.replace(/[, ].*/, '')}/getRecommendArticle`, {
            jsonpCallback: 'getRecommendArticle',
            cache: false,
        });

        if (data.data && data.data.length > 0) {
            result = [...data.data, ...recommendDefault];

            result = result.slice(0, 14);

            result.forEach((item) => {
                delete item.page;
            });
        }
    }

    return result;
};

/**
 * 大家都在看接口
 * @param {Object} config
 * @param {String} type
 */
export const allLookData = async (config, type = 'article') => {
    if (!config) {
        config = {};
    }

    const aid = config.aid;
    const title = config.title;

    const data = await jsonp(`//c2.m.ifeng.com/newShareDocRelates?aid=${aid}&title=${title}&type=${type}`, {
        cache: false,
    });
    let result = '';

    if (data && data.length !== 0) {
        result = data;
    }

    return result;
};

/**
 * 小说推荐接口
 */
export const getNovelData = async () => {
    const data = await jsonp('//androidapi4.yc.ifeng.com/api/xw/cpc', {
        cache: false,
    });
    let result = '';

    if (data && data.data) {
        const novelUrl = data.data.url;

        if (novelUrl) {
            if (novelUrl.includes('?')) {
                data.data.url = `${novelUrl}&novelNew=1`;
            } else {
                data.data.url = `${novelUrl}?novelNew=1`;
            }
        }
        result = data.data;
    }

    return result;
};

/**
 * 好房推荐接口
 * @param {Number} id
 * @param {Number} type
 * @param {Number} roomId
 */
export const getHouseRecommend = async (dictId, type, roomId) => {
    const getHouseRecommendCb = createJsonpCallbackName('getHouseRecommendCb');

    return await jsonp(
        `${apiBaseUrl}/api/content/shareNews/getHouseRecommend/${dictId}/${type}/${roomId}/${getHouseRecommendCb}`,
        {
            jsonpCallback: getHouseRecommendCb,
            cache: 120,
        },
    );
};

// 分享页相关推荐数据
export const relateRecomData = async (config) => {
    if (!config) {
        config = {};
    }

    const isVideoShare =
        (typeof window === 'object' &&
            window.allData &&
            window.allData.docData &&
            window.allData.docData.isVideoShare) ||
        false;
    const id = config.id || '';
    const title = config.title;
    const content = config.content;
    const doc_type = config.doc_type;
    const page = config.pageNum;
    const n = 30;
    const showAdvert = 1;
    const os = config.os;
    const proid = isVideoShare ? 'ifengvideoh5' : 'ifengnewsh5';
    const gv = '6.2.5';
    const uid = config.uid;

    const data = await jsonp(
        `//api.iclient.ifeng.com/client_share_news_recommend_new?id=${id}&title=${title}&content=${content}&doc_type=${doc_type}&page=${page}&n=${n}&showAdvert=${showAdvert}&os=${os}&proid=${proid}&gv=${gv}&uid=${uid}`,
        {
            jsonpCallback: `relateRecomCb${page}`,
            cache: true,
        },
    );

    let result = '';

    if (data.recomendData && data.recomendData.length !== 0) {
        result = data;
    }

    return result;
};

/**
 * 短内容相关获取
 * @param {object} config
 */
export const getShortRecommend = async (config) => {
    const getShortRecommendCb = createJsonpCallbackName('getShortRecommendCb');
    const configArr = [];

    for (const item in config) {
        configArr.push(`${item}=${config[item]}`);
    }
    const configVal = configArr.join('&');

    // return await jsonp(`${apiBaseUrl}/api/content/shareNews/getShortRelate/${getShortRecommendCb}`, {
    return await jsonp(`https://nine.ifeng.com/newRelatedDocs?${configVal}`, {
        jsonpCallback: getShortRecommendCb,
        cache: 120,
    });
};

/**
 * 分享页相关视频数据
 * @param {String} articleId
 * @param {String} title
 */
export const relateVideoData = async (articleId, title) => {
    const data = await jsonp(`//api.iclient.ifeng.com/NewRelativeVideoList?gv=5.6.0&guid=${articleId}&title=${title}`, {
        jsonpCallback: 'relateVideoCb',
        cache: false,
    });
    let result = '';

    if (data.items && data.items.length !== 0) {
        result = data.items;
    }

    return result;
};

/**
 * 房产/小区接口
 * @param {类型1:楼盘 2:小区} type
 * @param {String} id
 */
export const getHouseBuild = async (id, type) => {
    const getHouseBuildCb = createJsonpCallbackName('getHouseBuildCb');

    return await jsonp(`${apiBaseUrl}/api/content/shareNews/getHouseBuild/${id}/${type}/${getHouseBuildCb}`, {
        jsonpCallback: getHouseBuildCb,
        cache: 120,
    });
};

/**
 * 房源接口
 * @param {String} id
 */
export const getHouseSource = async (id) => {
    const getHouseSourceCb = createJsonpCallbackName('getHouseSourceCb');

    return await jsonp(`${apiBaseUrl}/api/content/shareNews/getHouseSource/${id}/${getHouseSourceCb}`, {
        jsonpCallback: getHouseSourceCb,
        cache: 120,
    });
};

/**
 * 房产活动接口
 * @param {String} id
 */
export const getHouseActivity = async (id) => {
    const getHouseActiveCb = createJsonpCallbackName('getHouseActiveCb');

    return await jsonp(`${apiBaseUrl}/api/content/shareNews/getHouseActive/${id}/${getHouseActiveCb}`, {
        jsonpCallback: getHouseActiveCb,
        cache: 120,
    });
};

/**
 * 房产调查接口
 * @param {String} id
 */
export const getHouseSurvey = async (id) => {
    const getHouseSurveyCb = createJsonpCallbackName('getHouseSurveyCb');

    return await jsonp(`${apiBaseUrl}/api/content/shareNews/getHouseSurvey/${id}/${getHouseSurveyCb}`, {
        jsonpCallback: getHouseSurveyCb,
        cache: 120,
    });
};

// 获取推荐内容评论数
export const getRecommendInfo = async () => {
    const result = await jsonp(`${apiBaseUrl}/api/content/graphic/recommend/getRecommend`, {
        jsonpCallback: 'getRecommend',
        cache: false,
    });

    let recommendData = [];

    if (result.data) {
        const { data, count } = result.data;

        recommendData = data.map((item, index) => ({
            id: item.id,
            base62Id: item.id.indexOf('ucms') > -1 ? item.id.slice(5) : item.id,
            title: item.title,
            url: formatUrl(item.pcUrl),
            pcUrl: item.pcUrl,
            count: count[index],
            createtime:
                item.createtime.split('-')[0] < 2018
                    ? item.createtime
                    : `${item.createtime.split('-')[1]}-${item.createtime.split('-')[2]}`,
            thumbnailpic:
                item.sourceFrom === 'video' ? formatImage(item.postUrl, 144, 80) : formatImage(item.thumbnail, 144, 80),
            source: item.sourceFrom === 'video' ? item.mediaName : item.source,
            sourceFrom: item.sourceFrom,
        }));
        if (window && window.allData && window.allData.docData && window.allData.docData.base62Id) {
            recommendData = recommendData.filter((item) => item.base62Id !== window.allData.docData.base62Id);
        }
        recommendData = recommendData.slice(0, 45);
    }

    return recommendData;
};

// 获取热门文章数据
export const getArticle = async () => {
    const hostname = window?.location?.hostname || 'news.ifeng.com';
    // const data = await ajax(`${apiBaseUrl}/api/${hostname}/hot/article`);
    const data = await jsonp(`${apiBaseUrl}/api/${hostname}/hot/getArticle`, {
        jsonpCallback: 'getArticle',
        cache: false,
    });

    let articleData = [];

    if (data.data) {
        articleData = data.data.slice(0, 10).map((item) => ({
            url: formatUrl(item.url),
            title: item.title,
            img: item.img === '' ? '' : formatImage(item.img, 100, 56),
        }));
    }

    return articleData;
};

// 获取精彩视频数据
export const getVideo = async () => {
    const data = await jsonp(`${apiBaseUrl}/api/content/graphic/video/getVideo`, {
        jsonpCallback: 'getVideo',
        cache: false,
    });

    let videoData = [];

    if (data.data) {
        videoData = data.data.slice(0, 10).map((item) => ({
            url: formatUrl(item.url),
            title: item.title,
            img: item.img === '' ? '' : formatImage(item.img, 100, 56),
        }));
    }

    return videoData;
};

// 获取凤凰无线数据
export const getWirelessData = async () => {
    const data = await jsonp(`${apiBaseUrl}/api/content/graphic/wireless/getWirelessData`, {
        jsonpCallback: 'getWirelessData',
        cache: false,
    });

    let result = [];

    if (data.code === 0 && data.data && data.data instanceof Array) {
        result = data.data.slice(0, 2);

        for (const item of result) {
            item.image_url = formatImage(item.image_url, 140, 80);
            item.link_url = formatUrl(item.link_url);
        }
    }

    return result;
};

// 原创 获取栏目往期文章
export const getSection = async (base62Id, searchPath) => {
    const data = await jsonp(`${apiBaseUrl}/api/content/original/getSection/${searchPath}/callback`, {
        jsonpCallback: 'callback',
        cache: false,
    });

    let list = data.data.filter((item) => item.base62Id !== base62Id);

    list = list.slice(0, 10).map((item) => ({
        url: formatUrl(item.url),
        title: item.title,
    }));

    return list;
};

/**
 * 获取Card数据
 * @param {String} id
 */
export const getArtilceCard = async (id, type) => {
    const getArtilceCardCb = createJsonpCallbackName('getArtilceCardCb');

    return await jsonp(
        `${apiBaseUrl}/api/content/shareNews/getArticleCard/${id}/${type || 'type'}/${getArtilceCardCb}`,
        {
            jsonpCallback: getArtilceCardCb,
            cache: 120,
        },
    );
};

// 获取热榜数据前五条
export const getHotRankList = async () => {
    const getHotRankListCb = createJsonpCallbackName('getHotRankListCb');

    return await jsonp(`${apiBaseUrl}/api/content/shareNews/getHotRankList/${getHotRankListCb}`, {
        jsonpCallback: getHotRankListCb,
        cache: 120,
    });
};

/**
 * 大家都在看&&推荐视频
 * @param {Object} config
 * @param {String} type
 */
export const getSpecificList = async (id, from, n, type) => {
    const data = await jsonp(
        `//nine.ifeng.com/relateshare?id=${id}&page=1&n=${n}&uid=${getUserkey()}&gv=7.27.0&proid=ifengnewsh5&os=${getOs()}&type=${
            type || 'doc'
        }&from=${from}`,
        {
            cache: false,
        },
    );

    let result = [];

    if (data && data.code === 0 && data.data && data.data.length > 0) {
        result = data.data;
    }

    return result;
};

/**
 * 获取ucms文章相关推荐数据列表
 * @param {array} base64List
 */
export const getRelateFeedList = async (base64List = []) => {
    let result = [];

    if (base64List.length === 0) return result;
    const base64String = base64List.join(',');
    const getRelateFeedList = createJsonpCallbackName('getRelateFeedList');
    const data = await jsonp(
        `https://shankapi.ifeng.com/feedflow/getFeedflowListByUcmsIds/${base64String}/${getRelateFeedList}`,
        {
            jsonpCallback: getRelateFeedList,
            cache: false,
        },
    );

    if (data && data.code === 0 && data.data && data.data.length > 0) {
        result = data.data;
    }

    return result;
};

export const getCommentCount = async (skey, commentUrl) => {
    const getRelateFeedList = createJsonpCallbackName('getCommentCount');

    const data = await jsonp(
        `https://comment.ifeng.com/get.php?orderby=uptimes&docUrl=${commentUrl}&format=js&job=1&p=1&pageSize=3&skey=${skey}`,
        {
            jsonpCallback: getRelateFeedList,
            cache: false,
        },
    );

    let result = [];

    if (data?.comments?.[0]) {
        result = data.comments;
    }

    return result;
};

export const getBigbangCard = async (configData) => {
    const config = {
        postData: {
            content: {
                data: configData,
                type: 'bflow',
            },
        },
    };
    const data = await axios.post(`${apiBaseUrl}/api/content/shareNews/bigbangCard`, config);

    if (data?.data?.data) {
        return data?.data?.data;
    } else {
        return {};
    }
};

// 获取地方站加权统计
export const getLocalWeigthVoteNum = async (url) => {
    const data = await jsonp('//survey.news.ifeng.com/api/getaccumulatorweight', {
        data: {
            format: 'js',
            key: url,
            serviceid: 3,
        },
        jsonpCallback: 'getaccumulator',
        cache: false,
    });

    return data?.code === 1 ? data?.data : null;
};

// 获取分享二维码
export const getQrCode = async (url) => {
    return await jsonp(`//qrcode.ifeng.com/qrcode.php?url=${encodeURIComponent(url)}&_share=weixin`, {
        jsonpCallback: 'getQrCode',
        cache: false,
    });
};

export const setReadNum = async (id, publishTimeStamp) => {
    const data = await jsonp(
        `https://survey.news.ifeng.com/api/accumulator?key=sharePageRead_${id}&format=js&createtime=${publishTimeStamp}`,
        {
            jsonpCallback: 'setReadNum',
            cache: false,
        },
    );

    let result = 0;

    if (data?.code === 1 && data?.data?.browse?.[`sharePageRead_${id}`]) {
        result = data.data.browse[`sharePageRead_${id}`];
    }

    return result;
};

export const getReadNumWeight = async (id, publishTimeStamp, serviceid = 5) => {
    const data = await jsonp(
        `https://survey.news.ifeng.com/api/getaccumulatorweight?key=sharePageRead_${id}&serviceid=${serviceid}&format=js&createtime=${publishTimeStamp}`,
        {
            jsonpCallback: 'getReadNumWeight',
            cache: false,
        },
    );

    let result = 0;

    if (data?.code === 1 && data?.data?.browse?.[`sharePageRead_${id}`]) {
        result = data.data.browse[`sharePageRead_${id}`];
    }

    return result;
};

export const getReadNumNormal = async (id, publishTimeStamp, serviceid = 5) => {
    const data = await jsonp(
        `https://survey.news.ifeng.com/api/getaccumulator?key=sharePageRead_${id}&serviceid=${serviceid}&format=js&createtime=${publishTimeStamp}`,
        {
            jsonpCallback: 'getReadNumNormal',
            cache: false,
        },
    );

    let result = 0;

    if (data?.code === 1 && data?.data?.browse?.[`sharePageRead_${id}`]) {
        result = data.data.browse[`sharePageRead_${id}`];
    }

    return result;
};

export const getPCUnlockArticle = async (userid) => {
    const data = await jsonp(`https://shankapi.ifeng.com/api/web/get/${userid}/auth/getPCUnlockArticle`, {
        jsonpCallback: 'getPCUnlockArticle',
        cache: false,
    });

    if (data?.data === '1') {
        return true;
    } else {
        return false;
    }
};
