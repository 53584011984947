import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import LeftContent from './leftContent';
import RightContent from './rightContent';
import { isIE, getDomArrByProps } from '../../../../utils/utils';

import 'intersection-observer';

const SsrIntersectionObserver = function SsrIntersectionObserver() {};

SsrIntersectionObserver.prototype = {
    observe: function observe() {},
    unobserve: function unobserve() {},
};

const RealIntersectionObserver =
    typeof IntersectionObserver !== 'undefined' ? IntersectionObserver : SsrIntersectionObserver;

const observer = new RealIntersectionObserver((changes) => {
    changes
        .filter((item) => item.isIntersecting > 0)
        .forEach((change) => {
            const container = change.target;

            container.style.backgroundImage = `url(${container.getAttribute('data-img')})`;
            container.removeAttribute('data-img');

            observer.unobserve(container);
        });
});

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        if (isIE(7) || isIE(8)) {
            const domArr = getDomArrByProps('span', 'data-img');

            domArr.forEach((item) => {
                item.style.backgroundImage = `url(${item.getAttribute('data-img')})`;
                item.removeAttribute('data-img');
            });
        }
    }

    render() {
        const { content } = this.props;
        const { leftContentData, rightContentData } = content;

        return (
            <div className={styles.content}>
                <LeftContent content={leftContentData} observer={observer} />
                <NoSSR>
                    <RightContent content={rightContentData} observer={observer} />
                </NoSSR>
                <div className="clear" />
            </div>
        );
    }
}

export default Content;
