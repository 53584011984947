import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { groundsAction } from '@src/utils/clientIframeGrouds';

class Iframe extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        index: PropTypes.number,
    };

    iframeId = `articleListIframe_${this.props.index}`;

    componentDidMount() {
        const { content } = this.props;
        const clientUrl = content?.urls?.pc || '';

        if (clientUrl) {
            new groundsAction(this.iframeId);
        }
    }

    render() {
        const { content } = this.props;
        const clientUrl = content?.urls?.pc || '';
        const iframeUrl = clientUrl.includes('?')
            ? `${clientUrl}&iframeId=${this.iframeId}`
            : `${clientUrl}?iframeId=${this.iframeId}`;

        return clientUrl ? (
            <iframe
                className={styles.iframeTag}
                id={this.iframeId}
                height="0"
                scrolling="no"
                frameBorder="0"
                data-src={iframeUrl}
                src=""
            />
        ) : null;
    }
}

export default Iframe;
