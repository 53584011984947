import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { isIE } from '../../../../../../utils/utils';

class WutonghuiContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        observer: PropTypes.object,
    };

    container = React.createRef();

    componentDidMount() {
        const { observer } = this.props;

        Array.from(this.container.current.querySelectorAll('[data-img]')).forEach(item => {
            observer.observe(item);
        });
    }

    render() {
        const { content } = this.props;

        return (
            <ul className={styles.list} ref={this.container}>
                {content.map(item => (
                    <li key={item.url}>
                        <a href={item.url} target="_blank">
                            <span
                                style={{ background: isIE(7) || isIE(8) ? `url(${item.img})` : '' }}
                                data-img={item.img}
                            />
                        </a>
                        <p>
                            <a href={item.url} target="_blank">
                                {item.title}
                            </a>
                        </p>
                    </li>
                ))}
            </ul>
        );
    }
}

export default errorBoundary(WutonghuiContent);
