/* eslint-disable */
export function groundsAction(iframeId) {
    this.iframeDom = document.getElementById(iframeId);
    this.iframeLoadDom = document.createElement('div');
    this.isAndroid = navigator.userAgent.toLocaleLowerCase().indexOf('android') != -1;
    this.isMessage = false;
    this.iframeStyleModeChange = function (theme) {
        if (theme === 'night') {
            var nightLogo = window.grounds
                ? 'iframe_loading_logo_night.png'
                : 'https://x0.ifengimg.com/ucms/2023_41/DD6326C67BC84A09AF41064CEC6B35C3392D95B4_size9_w405_h93.png';
            var nightLoading = window.grounds
                ? 'iframe_loading_circle_night.png'
                : 'https://x0.ifengimg.com/ucms/2023_41/FFCA68F770979AE756DB1BABBC16F6D32BC49812_size3_w48_h48.png';

            this.iframeLoadDom.style.width = '100%';
            this.iframeLoadDom.style.height = '221px';
            this.iframeLoadDom.style.backgroundColor = '#222226';
            this.iframeLoadDom.style.position = 'relative';
            this.iframeLoadDom.innerHTML =
                '<img class="iframeLoadLogo" src=' +
                nightLogo +
                ' /><img class="iframeLoadIcon" src=' +
                nightLoading +
                ' /><div class="iframeLoadText">正在加载</div><style>.iframeLoadLogo{width:134px;position:absolute;left:37px;top:67px}.iframeLoadIcon{width:16px;height:16px;position:absolute;left:46px;top:170px;animation:iframeLoadRotate 1s linear infinite}.iframeLoadText{position:absolute;left:72px;top:167px;font-size:16px;line-height:22px;color:#9E9E9E}@keyframes iframeLoadRotate{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}</style>';
        } else {
            var nightLogo = window.grounds
                ? 'iframe_loading_logo.png'
                : 'https://x0.ifengimg.com/ucms/2023_41/D2EA031623E13129A9F381579E41BC55827C3045_size9_w405_h93.png';
            var nightLoading = window.grounds
                ? 'iframe_loading_circle.png'
                : 'https://x0.ifengimg.com/ucms/2023_41/EAFA952DC83B8A9556B2E7E24F437922D0815B12_size2_w48_h48.png';

            this.iframeLoadDom.style.width = '100%';
            this.iframeLoadDom.style.height = '221px';
            this.iframeLoadDom.style.backgroundColor = '#fff';
            this.iframeLoadDom.style.position = 'relative';
            this.iframeLoadDom.innerHTML =
                '<img class="iframeLoadLogo" src=' +
                nightLogo +
                ' /><img class="iframeLoadIcon" src=' +
                nightLoading +
                ' /><div class="iframeLoadText">正在加载</div><style>.iframeLoadLogo{width:134px;position:absolute;left:37px;top:67px}.iframeLoadIcon{width:16px;height:16px;position:absolute;left:46px;top:170px;animation:iframeLoadRotate 1s linear infinite}.iframeLoadText{position:absolute;left:72px;top:167px;font-size:16px;line-height:22px;color:#9E9E9E}@keyframes iframeLoadRotate{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}</style>';
        }

        this.iframeDom.parentNode.insertBefore(this.iframeLoadDom, this.iframeDom.nextSibling);
    };
    this.iframeLoading = function () {
        var _this = this;
        var iframeSrc = _this.iframeDom.getAttribute('data-src') || _this.iframeDom.getAttribute('src');
        this.iframeDom.style.height = 0;
        this.iframeDom.style.visibility = 'hidden';

        if (_this.isAndroid) {
            _this.iframeDom.setAttribute('src', iframeSrc);
        } else {
            document.addEventListener('DOMContentLoaded', function () {
                _this.iframeDom.setAttribute('src', iframeSrc);
            });
        }

        if (window.grounds && window.grounds.getShowMode) {
            if (_this.isAndroid) {
                this.iframeStyleModeChange(window.grounds.getShowMode());
            } else {
                window.grounds.getShowMode(function (data) {
                    _this.iframeStyleModeChange(data);
                });
            }
            window.themeIframeDomCallBack = function (data) {
                _this.iframeStyleModeChange(data);
            };
            window.grounds.on('theme', 'themeIframeDomCallBack');
        } else {
            _this.iframeStyleModeChange('day');
        }
    };
    this.groundsPostMessage = function (groundsName, functionData) {
        this.iframeDom.contentWindow.postMessage(
            {
                groundsName,
                functionData: JSON.stringify(functionData),
            },
            '*',
        );
    };
    this.getMessage = function () {
        this.iframeLoading();
        var _this = this;
        var timer = null;

        window.addEventListener(
            'message',
            function (e) {
                if (e.data.groundsName) {
                    var data = e.data;
                    if (
                        data &&
                        data.groundsName &&
                        _this[data.groundsName] &&
                        data.functionData &&
                        data.functionData.iframeId === iframeId
                    ) {
                        _this.isMessage = true;
                        _this[data.groundsName](data.functionData || {});
                    }
                }
            },
            false,
        );
        window.addEventListener('resize', function () {
            clearTimeout(timer);
            timer = setTimeout(function () {
                _this.groundsPostMessage('windowResize', '');
            }, 300);
        });

        // 子ifarme未发通知处理
        setTimeout(function () {
            if (!_this.isMessage) {
                document.getElementById(iframeId).style.height = 'auto';
                if (_this.iframeLoadDom) {
                    _this.iframeLoadDom.parentNode.removeChild(_this.iframeLoadDom);
                    _this.iframeLoadDom = null;
                }
                _this.iframeDom.style.visibility = '';
            }
        }, 6000);
    };

    this.getMessage();
    this.getFontSize = function () {
        var _this = this;

        if (window.grounds && window.grounds.getFontSize) {
            if (_this.isAndroid) {
                _this.groundsPostMessage('getFontSize', window.grounds.getFontSize());
            } else {
                window.grounds.getFontSize(function (data) {
                    _this.groundsPostMessage('getFontSize', data);
                });
            }
        }
    };
    this.fontSize = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.fontSizeIframeCallBack = function (data) {
                _this.groundsPostMessage('getFontSize', data);
            };
            window.grounds.on('fontSize', 'fontSizeIframeCallBack');
        }
    };
    this.active = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.activeIframeCallBack = function (data) {
                _this.groundsPostMessage('active', data);
            };
            window.grounds.on('active', 'activeIframeCallBack');
        }
    };
    this.exit = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.exitIframeCallBack = function (data) {
                _this.groundsPostMessage('exit', data);
            };
            window.grounds.on('exit', 'exitIframeCallBack');
        }
    };
    this.webviewActive = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.webviewActiveIframeCallBack = function (data) {
                _this.groundsPostMessage('webviewActive', data);
            };
            window.grounds.on('webviewActive', 'webviewActiveIframeCallBack');
        }
    };
    this.theme = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.themeIframeCallBack = function (data) {
                _this.groundsPostMessage('getShowMode', data);
            };
            window.grounds.on('theme', 'themeIframeCallBack');
        }
    };
    this.getShowMode = function () {
        var _this = this;

        if (window.grounds && window.grounds.getShowMode) {
            if (_this.isAndroid) {
                _this.groundsPostMessage('getShowMode', window.grounds.getShowMode());
            } else {
                window.grounds.getShowMode(function (data) {
                    _this.groundsPostMessage('getShowMode', data);
                });
            }
        }
    };
    this.destroy = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.destroyIframeCallBack = function (data) {
                _this.groundsPostMessage('destroy', data);
            };
            window.grounds.on('destroy', 'destroyIframeCallBack');
        }
    };
    this.getLocation = function () {
        var _this = this;

        if (window.grounds && window.grounds.getLocation) {
            window.getLocationIframeCallBack = function (data) {
                _this.groundsPostMessage('getLocation', data);
            };
            window.grounds.getLocation('getLocationIframeCallBack');
        }
    };
    this.hasH5NeedPermission = function (data) {
        if (window.grounds && window.grounds.hasH5NeedPermission) {
            this.groundsPostMessage('hasH5NeedPermission', window.grounds.hasH5NeedPermission(data.data));
        }
    };
    this.requestH5NeedPermission = function (functionData) {
        var _this = this;

        if (window.grounds && window.grounds.requestH5NeedPermission) {
            window.requestH5NeedPermissionIframeCallBack = function (data) {
                _this.groundsPostMessage('requestH5NeedPermission', data);
            };
            window.grounds.requestH5NeedPermission(
                functionData.permissionEn,
                functionData.permissionZH,
                'requestH5NeedPermissionIframeCallBack',
            );
        }
    };
    this.isPushActivated = function () {
        var _this = this;

        if (window.grounds && window.grounds.isPushActivated) {
            window.isPushActivatedIframeCallBack = function (data) {
                _this.groundsPostMessage('isPushActivated', data);
            };
            window.grounds.isPushActivated('isPushActivatedIframeCallBack');
        }
    };
    this.getDeviceInfo = function () {
        if (window.grounds && window.grounds.getDeviceInfo) {
            var deviceInfo = {
                getGv: window.grounds.getDeviceInfo().getGv(),
                getAv: window.grounds.getDeviceInfo().getAv(),
                getOs: window.grounds.getDeviceInfo().getOs(),
                getDeviceId: window.grounds.getDeviceInfo().getDeviceId(),
                getProid: window.grounds.getDeviceInfo().getProid(),
                getUid: window.grounds.getDeviceInfo().getUid(),
                getVt: window.grounds.getDeviceInfo().getVt(),
                getScreen: window.grounds.getDeviceInfo().getScreen(),
                getPublishid: window.grounds.getDeviceInfo().getPublishid(),
                getDf: window.grounds.getDeviceInfo().getDf(),
                getPushInfo: window.grounds.getDeviceInfo().getPushInfo(),
                getManufacturer: window.grounds.getDeviceInfo().getManufacturer(),
            };
            this.groundsPostMessage('getDeviceInfo', deviceInfo);
        }
    };
    this.getUserInfo = function () {
        if (window.grounds && window.grounds.getUserInfo) {
            var userInfo = {
                getToken: window.grounds.getUserInfo().getToken(),
                getGuid: window.grounds.getUserInfo().getGuid(),
                getOs: window.grounds.getUserInfo().getOs(),
                getDeviceId: window.grounds.getUserInfo().getDeviceId(),
                getNickname: window.grounds.getUserInfo().getNickname(),
                getUserImage: window.grounds.getUserInfo().getUserImage(),
                getPhoneNum: window.grounds.getUserInfo().getPhoneNum(),
                getVipStatus: window.grounds.getUserInfo().getVipStatus(),
            };

            this.groundsPostMessage('getUserInfo', userInfo);
        }
    };
    this.networkchange = function () {
        var _this = this;

        if (window.grounds && window.grounds.on) {
            window.networkchangeIframeCallBack = function (data) {
                _this.groundsPostMessage('networkchange', data);
            };

            window.grounds.on('networkchange', 'networkchangeIframeCallBack');
        }
    };
    this.pushState = function () {
        var _this = this;

        if (window.grounds && window.grounds.pushState) {
            window.pushStateCallBack = function (data) {
                _this.groundsPostMessage('pushState', data);
            };
            window.grounds.pushState();
        }
    };
    this.userIsLogin = function () {
        if (window.grounds && window.grounds.userIsLogin) {
            this.groundsPostMessage('userIsLogin', window.grounds.userIsLogin());
        }
    };
    this.getPageInfo = function () {
        if (window.grounds && window.grounds.getPageInfo) {
            var pageInfo = {
                rnum: window.grounds.getPageInfo().getRnum(),
                ref: window.grounds.getPageInfo().getRef(),
                showtype: window.grounds.getPageInfo().getShowtype(),
                tag: window.grounds.getPageInfo().getTag(),
                pagetag: window.grounds.getPageInfo().getPagetag(),
            };
            this.groundsPostMessage('getPageInfo', pageInfo);
        }
    };
    this.disableBounceEffect = function (data) {
        if (window.grounds && window.grounds.disableBounceEffect) {
            window.grounds.disableBounceEffect(data);
        }
    };
    this.actionStat = function (data) {
        if (window.grounds && window.grounds.actionStat) {
            var config = {
                rnum: window.grounds.getPageInfo().getRnum(),
                ref: window.grounds.getPageInfo().getRef(),
                showtype: window.grounds.getPageInfo().getShowtype(),
                tag: window.grounds.getPageInfo().getTag(),
                ...data,
            };
            window.grounds.actionStat(JSON.stringify(config));
        }
    };
    this.expose = function (data) {
        if (window.grounds && window.grounds.expose) {
            var config = {
                rnum: window.grounds.getPageInfo().getRnum(),
                ref: window.grounds.getPageInfo().getRef(),
                showtype: window.grounds.getPageInfo().getShowtype(),
                tag: window.grounds.getPageInfo().getTag(),
                ...data,
            };
            window.grounds.expose(JSON.stringify(config));
        }
    };
    this.pageStat = function (data) {
        if (window.grounds && window.grounds.pageStat) {
            var config = {
                rnum: window.grounds.getPageInfo().getRnum(),
                ref: window.grounds.getPageInfo().getRef(),
                showtype: window.grounds.getPageInfo().getShowtype(),
                tag: window.grounds.getPageInfo().getTag(),
                ...data,
            };
            window.grounds.pageStat(JSON.stringify(config));
        }
    };
    this.dispatchByLink = function (data) {
        if (window.grounds && window.grounds.dispatchByLink) {
            data.pageLogParams = {
                rnum: window.grounds.getPageInfo().getRnum(),
                ref: window.grounds.getPageInfo().getRef(),
                showtype: window.grounds.getPageInfo().getShowtype(),
                tag: window.grounds.getPageInfo().getTag(),
                ...data.pageLogParams,
            };
            window.grounds.dispatchByLink(JSON.stringify(data));
        }
    };
    this.gotoTabMenu = function (data) {
        if (window.grounds && window.grounds.gotoTabMenu) {
            window.grounds.gotoTabMenu(data.tabMenu, data.channelId);
        }
    };
    this.newShare = function (data) {
        if (window.grounds && window.grounds.newShare) {
            window.grounds.newShare(JSON.stringify(data));
        }
    };
    this.openShare = function (data) {
        if (window.grounds && window.grounds.openShare) {
            window.grounds.openShare(data.shareurl, data.title, data.desc, data.thumbnail, data.documentId);
        }
    };
    this.shareWebCard = function (data) {
        if (window.grounds && window.grounds.shareWebCard) {
            window.grounds.shareWebCard(data.imageBase64, JSON.stringify(data.paramsMap));
        }
    };
    this.pushSwitch = function () {
        if (window.grounds && window.grounds.pushSwitch) {
            window.grounds.pushSwitch();
        }
    };
    this.setNoSlideArea = function (data) {
        if (window.grounds && window.grounds.setNoSlideArea) {
            var iframeOffsetTop = 0;
            var element = this.iframeDom;
            var areaObject = {};
            var areaGroup = [];

            while (element) {
                iframeOffsetTop += element.offsetTop;
                element = element.offsetParent;
            }
            if (data && data.area && data.area.length > 0) {
                data.area.forEach(function (item) {
                    areaGroup.push({
                        top: String(iframeOffsetTop + Number(item.top)),
                        end: String(iframeOffsetTop + Number(item.end)),
                        type: item.type,
                    });
                });
            }
            areaObject = {
                totalHeight: data.totalHeight,
                area: areaGroup,
            };
            window.grounds.setNoSlideArea(JSON.stringify(areaObject));
        }
    };
    this.simpleToast = function (data) {
        if (window.grounds && window.grounds.simpleToast) {
            console.log(data.data, '父吐司日志');
            window.grounds.simpleToast(data.data);
        }
    };
    this.userLogin = function () {
        if (window.grounds && window.grounds.userLogin) {
            window.grounds.userLogin();
        }
    };
    this.onIframeHeightChanged = function (data) {
        if (data && data.iframeId && document.getElementById(data.iframeId)) {
            document.getElementById(data.iframeId).style.height = data.height + 'px';
            if (this.iframeLoadDom) {
                this.iframeLoadDom.parentNode.removeChild(this.iframeLoadDom);
                this.iframeLoadDom = null;
            }
            this.iframeDom.style.visibility = '';
            if (window.grounds && window.grounds.onIframeHeightChanged) {
                window.grounds.onIframeHeightChanged(data.iframeId);
            }
        }
    };
}

/* eslint-enable */
