import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';

class TimeLine extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return content && content.title ? (
            <a href={content?.url} target="_blank" className={styles.timeLine}>
                <div className={styles.feedInfo}>
                    <div className={styles.infoText}>本文收录于热点追踪</div>
                    <div className={styles.infoStatus}>持续更新中</div>
                </div>
                <div className={styles.timeLineFeed}>
                    {content?.thumbnail && (
                        <img className={styles.feedPic} src={formatImageWebpAuto(content.thumbnail, 140, 80)} />
                    )}
                    <div className={styles.feedText}>
                        <div className={styles.feedTitle}>{content.title}</div>
                        {content?.title2 && <div className={styles.feedBref}>{content.title2}</div>}
                    </div>
                </div>
            </a>
        ) : null;
    }
}

export default errorBoundary(TimeLine);
