/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Title from './Tab/Title';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
const { formatImage } = require('@ifeng-fe/public_method');

class RightArticle extends React.PureComponent {
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { content, url } = this.props;

        return (
            <div className={styles.right_article}>
                <a className={styles.first_item} data-innermask href={content?.[0]?.url} target="_blank">
                    <img src={formatImage(content?.[0]?.thumbnails?.image?.[0]?.url, 300, 169)} />
                    <p data-innermask title={content?.[0]?.title}>
                        {content?.[0]?.title}
                    </p>
                </a>
                {content.slice(1).map((item, index) => {
                    return (
                        <a className={styles.item} key={index} href={item.url} target="_blank">
                            {item.title}
                        </a>
                    );
                })}
                <a className={styles.check_more} href={url} target="_blank">
                    <span>查看更多</span>
                </a>
            </div>
        );
    }
}

export default errorBoundary(RightArticle);
