import React from 'react';
import NoSSR from 'react-no-ssr';
import Chip from 'Chip';
import PropTypes from 'prop-types';
import styles from './index.css';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Search from '@ifeng-fe/ui_pc_search';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from '../../../components/nav';
import Logo from './logo';

class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        const { content } = this.props;
        const { nav, search, adMap, topAd, bannerAd, logoAd, logo, domain, breadCrumbdata } = content;
        const logoData = { logo, domain, breadCrumbdata };

        return (
            <div>
                <div className={styles.header}>
                    <Chip id="20002" type="struct" title="导航" groupName="头部" content={nav}>
                        <Nav />
                    </Chip>
                    <div className={styles.user}>
                        <NoSSR>
                            <UserInfo />
                        </NoSSR>
                    </div>
                </div>
                <div className={styles.topAd_box}>
                    <NoSSR>
                        {bannerAd ? (
                            <Ad content={bannerAd} styleName={styles.topAd} />
                        ) : (
                            <Ad content={topAd} styleName={styles.topAd} />
                        )}
                    </NoSSR>
                </div>
                <div className={styles.search_bar}>
                    <Logo content={logoData} />
                    <NoSSR>
                        <Chip id="20005" type="struct" title="搜索" groupName="头部" content={search}>
                            <Search />
                        </Chip>
                    </NoSSR>
                    <NoSSR>{adMap.logoAd ? <Ad content={logoAd} /> : ''}</NoSSR>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Header);
