import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Welfare extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { titlePic, qrcodePic },
        } = this.props;

        return (
            <div className={styles.box}>
                <div className={styles.title_box}>
                    <img src={titlePic} />
                </div>
                <div className={styles.pic_box}>
                    <img src={qrcodePic} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Welfare);
