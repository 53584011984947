import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import styles from './index.css';
import Loadable from 'react-loadable';
import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';
import { Survey } from 'ThreeTerminal_pc_dynamic';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Text from './text';
import Goods from './goods';
// import Video from './video';
import Video from './video2';
import PageList from './pageList';
import Iframe from './iframe';
import CutOff from './cutOff';

const LoadaMyAudio = Loadable({
    loader: () => import('./audio'),
    loading: () => {
        return <div />;
    },
});

const LoadSlides = Loadable({
    loader: () => import('../slides'),
    loading: () => {
        return <div />;
    },
});

let enableVideosPlugin = true;
let enableSlidesPlugin = true;
let enableSurveyPlugin = true;

class ArticleContent extends React.PureComponent {
    static propTypes = {
        topic: PropTypes.string,
        content: PropTypes.object,
        pluginData: PropTypes.object,
        handleSelectTxt: PropTypes.func,
        cutofffConfig: PropTypes.object,
        isWutonghui: PropTypes.bool,
    };

    state = {
        cutoffHeight: 0,
    };

    articleBox = React.createRef();

    getVideosPlugin = () => {
        const { content, pluginData } = this.props;

        if (content.currentPage === 0 && enableVideosPlugin && pluginData.videosPluginData) {
            pluginData.videosPluginData.map((item, index) => {
                return <Video key={index} videoinfo={item} />;
            });
        } else {
            return null;
        }
    };

    getSlidesPlugin = () => {
        const { content, pluginData } = this.props;

        if (content.currentPage === 0 && enableSlidesPlugin && pluginData.slidesPluginData) {
            pluginData.slidesPluginData.map((item, index) => (
                <LazyLoad key={index}>
                    <LoadSlides limit={10} attachmentId={item.attachmentId} />
                </LazyLoad>
            ));
        } else {
            return null;
        }
    };

    getSurveyPlugin = () => {
        const { content, pluginData } = this.props;

        if (content.currentPage === 0 && enableSurveyPlugin && pluginData.surveyPluginData) {
            return <Survey surveyId={pluginData.surveyPluginData.attachmentId} />;
        } else {
            return null;
        }
    };

    getcontent = (type, data, index) => {
        const { topic, handleSelectTxt, pluginData, isWutonghui } = this.props;

        if (type === 'text') {
            return <Text key={index} content={data} handleMouseUp={handleSelectTxt} isWutonghui={isWutonghui} />;
        } else if (type === 'survey') {
            return (
                <LazyLoad key={index}>
                    <Survey surveyId={data.attachmentId} />
                </LazyLoad>
            );
        } else if (type === 'slide') {
            return (
                <LazyLoad key={index}>
                    <LoadSlides limit={10} attachmentId={data.attachmentId} />
                </LazyLoad>
            );
        } else if (type === 'video') {
            return <Video key={index} topic={topic} videoinfo={data} pluginData={pluginData} />;
        } else if (type === 'audio') {
            return (
                <LazyLoad key={index}>
                    <LoadaMyAudio audioinfo={data} />
                </LazyLoad>
            );
        } else if (type === 'goods') {
            return <Goods key={index} content={data} pluginData={pluginData} />;
        } else if (type === 'iframe') {
            return <Iframe key={index} index={index} content={data} />;
        }
    };

    setCutoffHeight = (value) => {
        this.setState({
            cutoffHeight: value,
        });
    };

    render() {
        const { content, cutofffConfig } = this.props;
        const { cutoffHeight } = this.state;

        /**
         * 当文章处于第一页时，需要判断文章数据是否有视频，轮播图，调查的内容数据和插件数据
         * 当内容数据没有时，以插件数据为主；内容数据存在时，则以内容数据为主
         */
        if (content.currentPage === 0) {
            enableVideosPlugin = true;
            enableSlidesPlugin = true;
            enableSurveyPlugin = true;

            content.contentList.forEach((item) => {
                if (item.type === 'video') {
                    enableVideosPlugin = false;
                } else if (item.type === 'slide') {
                    enableSlidesPlugin = false;
                } else if (item.type === 'survey') {
                    enableSurveyPlugin = false;
                }
            });
        } else {
            enableVideosPlugin = false;
            enableSlidesPlugin = false;
            enableSurveyPlugin = false;
        }

        return (
            <div className={styles.articleContent}>
                <div
                    className={styles.articleBox}
                    ref={this.articleBox}
                    style={{ height: cutoffHeight ? `${cutoffHeight}px` : 'auto' }}>
                    {this.getVideosPlugin()}
                    {this.getSlidesPlugin()}
                    {content.contentList.map((item, index) => this.getcontent(item.type, item.data, index))}
                    {this.getSurveyPlugin()}
                    <NoSSR>
                        <PageList content={content} />
                    </NoSSR>
                    <div className={styles.end} />
                </div>
                {cutofffConfig?.isCutoff && (
                    <CutOff
                        cutofffConfig={cutofffConfig}
                        setCutoffHeight={this.setCutoffHeight}
                        articleBox={this.articleBox}
                    />
                )}
            </div>
        );
    }
}

export default errorBoundary(ArticleContent);
