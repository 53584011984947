import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Artical from './artical';
import Request from './request';
import Goodbook from './goodbook';

class LeftContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        observer: PropTypes.object,
    };

    render() {
        const { content, observer } = this.props;
        const {
            type,
            topic,
            allowComment,
            joinNum,
            count,
            article,
            article: { searchPath, base62Id, wemediaEAccountId },
            qrCode,
            audiobook,
            goodbook0,
            topicAd,
            contentAd,
            infoAd,
            hardAd,
            serviceAd,
            contentBottomAd,
            commentAd,
            isHitBlack,
            relatedRecommend,
            isCloseAlgRec,
            chQrCode,
            commentGuide,
            hasCopyRight,
            faceUrl,
            vestAccountDetail,
            subscribe,
            articleBottomAd,
            keywords,
            interact,
            commentBottomAd,
            timeLine,
            sourceReason,
            cutoffGray,
        } = content;

        const commentInfo = {
            allowComment,
            joinNum,
            count,
        };

        // 佛教无为您推荐内容
        return (
            <div className={styles.leftContent}>
                <Artical
                    type={type}
                    topic={topic || ''}
                    commentInfo={commentInfo}
                    content={article}
                    qrCode={qrCode}
                    isHitBlack={isHitBlack}
                    chQrCode={chQrCode}
                    topicAd={topicAd}
                    ad={contentAd}
                    commentAd={commentAd}
                    commentGuide={commentGuide}
                    hasCopyRight={hasCopyRight}
                    faceUrl={faceUrl}
                    vestAccountDetail={vestAccountDetail}
                    subscribe={subscribe}
                    articleBottomAd={articleBottomAd}
                    keywords={keywords}
                    interact={interact}
                    timeLine={timeLine}
                    sourceReason={sourceReason}
                    cutoffGray={cutoffGray}
                />
                <NoSSR>
                    <Ad content={commentBottomAd} styleName={styles.hardAd} />
                    <div className={styles.space30} />
                    {searchPath.indexOf('16-') === 0 ? null : (
                        <Request
                            isHitBlack={isHitBlack}
                            relatedRecommend={relatedRecommend}
                            isCloseAlgRec={isCloseAlgRec}
                            ad={infoAd}
                            observer={observer}
                            base62Id={base62Id}
                        />
                    )}
                    <div className={styles.space40} />
                    <Ad content={hardAd} styleName={styles.hardAd} />
                    <Ad content={serviceAd} styleName={styles.serviceAd} />
                    {!isHitBlack && audiobook ? (
                        <Chip
                            id="195116"
                            type="recommend"
                            title="互娱有声"
                            groupName="正文"
                            content={audiobook}
                            translate="handleGoodbookData">
                            <Goodbook title="精品有声" observer={observer} />
                        </Chip>
                    ) : (
                        ''
                    )}
                    <Ad content={contentBottomAd} styleName={styles.contentBottomAd} />
                    <div className={styles.space25} />
                    {!isHitBlack && goodbook0 ? (
                        <Chip
                            id="55001"
                            type="recommend"
                            title="正文好书精选"
                            groupName="正文"
                            content={goodbook0}
                            translate="handleGoodbookData">
                            <Goodbook title="好书精选" observer={observer} />
                        </Chip>
                    ) : (
                        ''
                    )}
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(LeftContent);
