import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getSkey, isIE } from '../../../../../../../../utils/utils';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { LazyImg } from '@ifeng-fe/ui_pc_lazyLoad';

class Info extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        count: PropTypes.object,
    };

    render() {
        const { data, count } = this.props;

        return (
            <div className={styles.info_box}>
                {data.thumbnailpic && data.thumbnailpic !== '' ? (
                    <div className={styles.pic}>
                        <a href={data.url} target="_blank">
                            <span
                                style={{ background: isIE(7) || isIE(8) ? `url(${data.thumbnailpic})` : '' }}
                                data-img={data.thumbnailpic}
                            />
                        </a>
                        {data.sourceFrom === 'video' ? <div className={styles.v_logo} /> : ''}
                    </div>
                ) : (
                    ''
                )}
                <div className={styles.text_box}>
                    <p className={styles.text}>
                        <a href={data.url} target="_blank" title={data.title}>
                            {data.title}
                        </a>
                    </p>
                    <p className={styles.time}>
                        {data.source ? <span className={styles.source}>{data.source}</span> : ''}
                        {data.createtime && data.createtime !== '' ? (
                            <span className={styles.date}>{data.newsTime}</span>
                        ) : (
                            ''
                        )}
                    </p>
                    {count ? (
                        <div className={styles.comment}>
                            {count.count ? (
                                <a href={`//gentie.ifeng.com/c/comment/${data.base62Id}`} target="_blank">
                                    {count.count}
                                </a>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Info);
