import { loadScript, cookie } from '@ifeng-fe/ui_base';
import { getScript } from './utils';

const bLevel = {
    qq: { forbid: 0, lower: 1, higher: 2 },
    uc: { forbid: 0, allow: 1 },
};
const qApiSrc = {
    lower: 'http://3gimg.qq.com/html5/js/qb.js',
    higher: 'http://jsapi.qq.com/get?api=app.share',
};
let UA = '';
let isqqBrowser = false;
let isucBrowser = false;

if (typeof window !== 'undefined') {
    UA = navigator.appVersion;
    isqqBrowser = UA.split('MQQBrowser/').length > 1 ? bLevel.qq.higher : bLevel.qq.forbid;
    isucBrowser = UA.split('UCBrowser/').length > 1 ? bLevel.uc.allow : bLevel.uc.forbid;
}

const version = {
    uc: '',
    qq: '',
};
let isWeixin = false;

const getUserid = () => {
    return cookie.get('userid');
};

const getSid = () => {
    return cookie.get('sid');
};

const isloadqqApi = async () => {
    if (isqqBrowser) {
        const url = version.qq < 5.4 ? qApiSrc.lower : qApiSrc.higher;

        await loadScript(url, { cache: false, reload: false });
    }
};

const getPlantform = () => {
    if (typeof navigator === 'undefined') {
        return '';
    }

    const ua = navigator.userAgent;

    if (ua.indexOf('iPhone') > -1 || ua.indexOf('iPod') > -1) {
        return 'iPhone';
    }

    return 'Android';
};

const is_weixin = () => {
    const a = UA.toLowerCase();

    return a.match(/MicroMessenger/i) === 'micromessenger';
};

const getVersion = c => {
    const a = c.split('.');
    const b = parseFloat(`${a[0]}.${a[1]}`);

    return b;
};

const init = () => {
    const platform_os = getPlantform();

    version.qq = isqqBrowser ? getVersion(UA.split('MQQBrowser/')[1]) : 0;
    version.uc = isucBrowser ? getVersion(UA.split('UCBrowser/')[1]) : 0;
    isWeixin = is_weixin();

    if (
        (isqqBrowser && version.qq < 5.4 && platform_os === 'iPhone') ||
        (isqqBrowser && version.qq < 5.3 && platform_os === 'Android')
    ) {
        isqqBrowser = bLevel.qq.forbid;
    } else if (isqqBrowser && version.qq < 5.4 && platform_os === 'Android') {
        isqqBrowser = bLevel.qq.lower;
    } else if (
        isucBrowser &&
        ((version.uc < 10.2 && platform_os === 'iPhone') || (version.uc < 9.7 && platform_os === 'Android'))
    ) {
        isucBrowser = bLevel.uc.forbid;
    }

    isloadqqApi();

    if ((isqqBrowser || isucBrowser) && !isWeixin) {
        return 'all';
    } else {
        return 'part';
    }
};

const nativeShare = (config, from, dataApp) => {
    const platform_os = getPlantform();
    const source = from;

    config = config || {};
    from = config.from || window.location.host || '';
    const url = config.url || document.location.href || '';
    const title = config.title || document.title || '';
    const desc = config.desc || document.title || '';
    const img =
        config.img ||
        (document.getElementsByTagName('img').length > 0 && document.getElementsByTagName('img')[0].src) ||
        '';
    const img_title = config.img_title || document.title || '';
    const summary = config.summary || '凤凰网';
    const ucAppList = {
        sinaWeibo: ['kSinaWeibo', 'SinaWeibo', 11, '新浪微博'],
        weixin: ['kWeixin', 'WechatFriends', 1, '微信好友'],
        weixinFriend: ['kWeixinFriend', 'WechatTimeline', '8', '微信朋友圈'],
        QQ: ['kQQ', 'QQ', '4', 'QQ好友'],
        QZone: ['kQZone', 'QZone', '3', 'QQ空间'],
    };

    if (source && window.setShareContent) {
        window.setShareContent({
            title,
            desc,
            url,
            wxthumb: img,
        });
    } else if (isucBrowser) {
        const ta = platform_os === 'iPhone' ? ucAppList[dataApp][0] : ucAppList[dataApp][1];

        dataApp = dataApp === '' ? '' : ta;
        if (dataApp === 'QZone') {
            // qq空间
            const href = `mqqapi://share/to_qzone?src_type=web&version=1&file_type=news&req_type=1&image_url=${img}&title=${title}&description=${desc}&url=${url}&app_name=${from}`;

            window.open(href);
        }
        if (typeof window.ucweb != 'undefined') {
            if (!dataApp) {
                const tanzhen = `http://stadig.ifeng.com/actsta.js?datatype=wap_act&value=article_share2&uri=${encodeURIComponent(
                    window.location.href,
                )}&ref=${encodeURIComponent(window.location.href)}&uid=${encodeURIComponent(
                    getUserid(),
                )}&sid=${encodeURIComponent(getSid())}&time=${encodeURIComponent(
                    new Date().valueOf(),
                )}&ci=${encodeURIComponent(window.gloableSettings.channelPath)}&pt=${encodeURIComponent(
                    window.gloableSettings.pt,
                )}`;

                getScript(tanzhen);

                return;
            }

            window.ucweb.startRequest('shell.page_share', [title, title, url, dataApp, '', `@${from}`, '']);
        } else if (typeof window.ucbrowser != 'undefined') {
            if (dataApp) {
                window.ucbrowser.web_share(title, title, url, dataApp, '', `@${from}`, '');
            } else {
                const tanzhen = `http://stadig.ifeng.com/actsta.js?datatype=wap_act&value=article_share2&uri=${encodeURIComponent(
                    window.location.href,
                )}&ref=${encodeURIComponent(window.location.href)}&uid=${encodeURIComponent(
                    getUserid(),
                )}&sid=${encodeURIComponent(getSid())}&time=${encodeURIComponent(
                    new Date().valueOf(),
                )}&ci=${encodeURIComponent(window.gloableSettings.channelPath)}&pt=${encodeURIComponent(
                    window.gloableSettings.pt,
                )}`;

                getScript(tanzhen);
            }
        }
    } else if (isqqBrowser && !isWeixin) {
        dataApp = dataApp === '' ? '' : ucAppList[dataApp][2];

        let ah = {
            url,
            title,
            description: desc,
            img_url: img,
            img_title,
            dataApp, // 微信好友1,腾讯微博2,QQ空间3,QQ好友4,生成二维码7,微信朋友圈8,啾啾分享9,复制网址10,分享到微博11,创意分享13
            cus_txt: '请���入此时此刻想要分享的内容',
        };

        ah = dataApp === '' ? '' : ah;
        if (typeof window.browser != 'undefined') {
            if (typeof window.browser.app != 'undefined' && isqqBrowser === bLevel.qq.higher) {
                window.browser.app.share(ah);
            }
        } else if (typeof window.qb != 'undefined' && isqqBrowser === bLevel.qq.lower) {
            window.qb.share(ah);
        }
    } else if (dataApp === 'sinaWeibo') {
        // 分享到微博
        const href = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(
            url,
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&pic=${encodeURIComponent(img)}`;

        window.open(href);
    } else if (dataApp === 'QZone') {
        // 分享到qq空间
        const href = `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
            url,
        )}&title=${encodeURIComponent(title)}&pics=${encodeURIComponent(img)}`;

        window.open(href);
    }

    let tag = '';

    if (dataApp === 'QZone') {
        tag = 'm_qqZone';
    } else if (dataApp === 'sinaWeibo') {
        tag = 'm_sina';
    } else if (dataApp === 'weixin') {
        tag = 'm_weixin';
    } else if (dataApp === 'QQ') {
        tag = 'm_qq';
    } else if (dataApp === 'weixinFriend') {
        tag = 'm_weixinMoment';
    }
    const tanzhen = `http://stadig.ifeng.com/actsta.js?datatype=wap_act&value=${tag}&uri=${encodeURIComponent(
        window.location.href,
    )}&ref=${encodeURIComponent(window.location.href)}&uid=${encodeURIComponent(getUserid())}&sid=${encodeURIComponent(
        getSid(),
    )}&time=${encodeURIComponent(new Date().valueOf())}&ci=${encodeURIComponent(
        window.gloableSettings.channelPath,
    )}&pt=${encodeURIComponent(window.gloableSettings.pt)}`;

    getScript(tanzhen);
};

export { getPlantform, init, nativeShare };
