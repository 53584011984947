import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Loadable from 'react-loadable';
import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from 'ThreeTerminal_base/publicMethod';

const LoadWeibo = Loadable({
    loader: () => import('../weibo'),
    loading() {
        return <div />;
    },
});

class Weixins extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.box}>
                <div className={styles.box_up}>
                    <div className={styles.pic}>
                        <p>
                            <img src={formatImage(content[0].qrCode, 80, 80)} width="80" height="80" />
                        </p>
                        <p className={styles.text}>{content[0].name}</p>
                    </div>
                    <div className={styles.detail}>
                        <p>
                            <a href={content[0].url} target="_blank" title={content[0].title}>
                                {content[0].title}
                            </a>
                        </p>
                        <LazyLoad>
                            <LoadWeibo uid={content[0].uid} />
                        </LazyLoad>
                    </div>
                </div>
                <div className={styles.box_down}>
                    <div className={styles.pic}>
                        <p>
                            <img src={formatImage(content[1].qrCode, 80, 80)} width="80" height="80" />
                        </p>
                        <p className={styles.text}>{content[1].name}</p>
                    </div>
                    <div className={styles.detail}>
                        <p>
                            <a href={content[1].url} target="_blank" title={content[1].title}>
                                {content[1].title}
                            </a>
                        </p>
                        <LazyLoad>
                            <LoadWeibo uid={content[1].uid} />
                        </LazyLoad>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Weixins);
