import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { isIE } from '../../../../../../utils/utils';

class WutonghuiPic extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        observer: PropTypes.object,
    };

    container = React.createRef();

    componentDidMount() {
        const { observer } = this.props;

        Array.from(this.container.current.querySelectorAll('[data-img]')).forEach((item) => {
            observer.observe(item);
        });
    }

    render() {
        const {
            content: { imageUrl, url },
        } = this.props;

        return (
            <div className={styles.pic_box} ref={this.container}>
                <a href={url} target="_blank">
                    <span style={{ background: isIE(7) || isIE(8) ? `url(${imageUrl})` : '' }} data-img={imageUrl} />
                    <img
                        className={styles.picMark}
                        src="https://x0.ifengimg.com/feprod/c/feprod/c/ifengimcp/pic/20210108/3677f2773fd79f12b079_size1_w56_h34.png"
                    />
                </a>
            </div>
        );
    }
}

export default errorBoundary(WutonghuiPic);
