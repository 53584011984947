import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class AsideTitle extends React.PureComponent {
    static propTypes = {
        url: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.object,
        children: PropTypes.object,
    };

    render() {
        let { url, title } = this.props;
        const { content, children } = this.props;

        if (content) {
            url = content.url;
            title = content.title;
        }

        return (
            <div className={styles.title}>
                {children}
                <h4>
                    {url ? (
                        <a href={url} target="_blank">
                            {title}
                        </a>
                    ) : (
                        title
                    )}
                </h4>
                {title === '凰家尚品' && (
                    <img className={styles.imgMark} src="https://x0.ifengimg.com/feprod/c/2023_1_17/16_21_21/1.png" />
                )}
            </div>
        );
    }
}

export default errorBoundary(AsideTitle);
