import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import ChipEdit from 'ChipEdit';
import transform from '../../../common/transform';
import { addEventListener } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getCommentCount } from '@ifeng-fe/container_content/common/client/services/api';
import Header from './header';
import Content from './content';
import BottomFooter from './bottomFooter';
import Affix from './affix';
// test

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        allowComment: false,
        joinNum: 0,
        count: 0,
    };

    async componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();

        this.unHandleClick = addEventListener(document, 'click', this.handleClick);

        const {
            docData: { commentUrl },
        } = this.props.content;

        try {
            const { count, join_count, allow_comment } = await getCommentCount(commentUrl);

            this.setState({
                allowComment: allow_comment !== -1,
                joinNum: join_count,
                count,
            });
        } catch (e) {
            console.error(e);
        }
    }

    componentWillUnmount() {
        this.unHandleClick();
    }

    // 全站渠道标记传递，文章页的任意跳转
    handleClick = (e) => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = (tag) => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    render() {
        const { allowComment, joinNum, count } = this.state;
        const { content } = this.props;
        const {
            topic,
            docData,
            docData: {
                type,
                bdImg,
                base62Id,
                logo,
                domain,
                breadCrumbdata,
                title,
                commentUrl,
                searchPath,
                relatedRecommend,
                faceUrl,
                vestAccountDetail,
                subscribe,
                wemediaEAccountId
            },
            nav,
            search,
            qrCode,
            audiobook,
            goodbook0,
            goodbook1,
            article,
            video,
            qrCodeSet,
            welfare,
            fhhQrcode,
            wireless,
            adMap,
            topAd,
            bannerAd,
            logoAd,
            topicAd,
            contentAd,
            infoAd,
            hardAd,
            serviceAd,
            contentBottomAd,
            commentAd,
            articleAd,
            videoAd,
            asideAd1,
            asideAd2,
            asideAd3,
            asideAd4,
            asideAd5,
            asideAd6,
            wutonghuiPic,
            wutonghuiTab,
            wutonghuiContent,
            bottomAd,
            footer,
            floatAd1,
            floatAd2,
            keywords,
            safeLevel,
            isCloseAlgRec,
            assess,
            chQrCode,
            commentGuide,
            hasCopyRight,
            articleBottomAd,
            interact,
            commentBottomAd,
            timeLine,
            sourceReason,
            cutoffGray,
        } = content;
        const isHitBlack = safeLevel === 1;

        const headerData = {
            nav,
            search,
            adMap,
            topAd,
            bannerAd,
            logoAd,
            logo,
            domain,
            breadCrumbdata,
        };
        const contentData = {
            leftContentData: {
                type,
                topic,
                allowComment,
                joinNum,
                count,
                article: docData,
                audiobook,
                qrCode,
                goodbook0,
                topicAd,
                contentAd,
                infoAd,
                hardAd,
                serviceAd,
                contentBottomAd,
                commentAd,
                isHitBlack,
                relatedRecommend,
                isCloseAlgRec,
                chQrCode,
                commentGuide,
                hasCopyRight,
                faceUrl,
                vestAccountDetail,
                subscribe,
                articleBottomAd,
                keywords,
                interact,
                commentBottomAd,
                timeLine,
                sourceReason,
                cutoffGray,
                wemediaEAccountId
            },
            rightContentData: {
                searchPath,
                article,
                video,
                qrCodeSet,
                welfare,
                fhhQrcode,
                wireless,
                goodbook1,
                articleAd,
                videoAd,
                asideAd1,
                asideAd2,
                asideAd3,
                asideAd4,
                asideAd5,
                asideAd6,
                wutonghuiPic,
                wutonghuiTab,
                wutonghuiContent,
                isHitBlack,
                assess,
            },
        };
        const footerData = { bottomAd, footer };

        const affixData = {
            base62Id,
            title,
            commentUrl,
            floatAd1,
            floatAd2,
        };

        return (
            <div>
                <img className={styles.bd} src={bdImg} width="121" height="75" alt={title} />
                <Header content={headerData} />
                <div className={styles.space40} />
                <Content content={contentData} />
                <div className={styles.space10} />
                <NoSSR>
                    <BottomFooter content={footerData} />
                    <Affix allowComment={allowComment} content={affixData} />
                </NoSSR>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
