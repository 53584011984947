import { loadScript, cookie } from 'ThreeTerminal_base/base';

const getATag = tag => {
    if (!tag) {
        return null;
    }

    if (tag.tagName !== 'A') {
        return getATag(tag.parentElement);
    } else {
        return tag;
    }
};

const getParam = (key, search) => {
    const params = search.replace('?', '').split('&');
    let ret = '';

    if (params) {
        for (let i = 0, len = params.length; i < len; i++) {
            const pair = params[i].split('=');

            if (key === pair[0]) {
                ret = pair[1];
            }
        }
    }

    return ret;
};

// 全站渠道标记传递，文章页的任意跳转
export const handleTjLink = e => {
    const tag = getATag(e.target);

    if (!tag) return;

    const search = location.search;
    const url = location.href;

    let newUrl = '';

    let inWhitelist = false;
    const whitelist = ['http://dol.deliver.ifeng.com/'];

    for (let i = 0, len = whitelist.length; i < len; i++) {
        if (url.indexOf(whitelist[i]) > -1) {
            inWhitelist = true;
        }
    }
    // 如果当前页面的search中有ch=，当前的a标签后缀添加ch=
    if (search && getParam('ch', search) && !inWhitelist) {
        const curSrc = getParam('ch', search);

        if (url.indexOf('?') > -1) {
            if (getParam('ch', url)) {
                // 有别的引流
                newUrl = url.replace(`ch=${getParam('ch', url)}`, `ch=${curSrc}`);
            } else {
                // 没有别的引流
                newUrl = `${url}&ch=${curSrc}`;
            }
        } else {
            newUrl = `${url}?ch=${curSrc}`;
        }

        window.location.href = newUrl;

        if (event && event.preventDefault) event.preventDefault();
        else window.event.returnValue = false;
    }
};

export const getScript = async (url, callback) => {
    await loadScript(url, { cache: false, reload: false });

    if (callback) callback();
};

export const getUserid = () => {
    return cookie.get('userid');
};

export const getUserkey = () => {
    return cookie.get('userkey');
};

export const getOs = () => {
    const UA = navigator.userAgent.toLocaleLowerCase();

    if (UA.includes('mobile')) {
        if (UA.includes('android')) {
            return 'android';
        }

        return 'ios';
    }

    return 'pc';
};

export const getSid = () => {
    return cookie.get('sid');
};

export const isIE = ver => {
    if (typeof document !== 'undefined') {
        const b = document.createElement('b');

        b.innerHTML = `<!--[if IE ${ver}]><i></i><![endif]-->`;

        return b.getElementsByTagName('i').length === 1;
    } else {
        return false;
    }
};

export const getDomArrByProps = (tagName, name) => {
    const selectDom = [];
    const dom = document.getElementsByTagName(tagName);

    for (let i = 0; i < dom.length; i++) {
        if ('' !== dom[i].getAttribute(name)) {
            selectDom.push(dom[i]);
        }
    }

    return selectDom;
};

/**
 * 图文单页热门文章和精彩视频推广数据处理方法
 * @param {array} content 推广位数据
 * @param {array} data 模块数据
 */
export const handleRecommendData = (content, data) => {
    let result = [];

    if (data) {
        content = content && content.length > 0 ? content : [];

        if (data.length < 4) {
            result = data.concat(content);
        } else if (data.length >= 4 && data.length <= 7) {
            result = [
                ...data.slice(0, 3),
                content[0] ? content[0] : data[3],
                data[4],
                content[1] ? content[1] : data[5],
                content[2] ? content[2] : data[6],
            ];
        } else if (data.length === 8) {
            result = [
                ...data.slice(0, 3),
                content[0] ? content[0] : data[3],
                data[4],
                content[1] ? content[1] : data[5],
                data[6],
                content[2] ? content[2] : data[7],
            ];
        } else if (data.length === 9) {
            result = [
                ...data.slice(0, 3),
                content[0] ? content[0] : data[3],
                data[4],
                content[1] ? content[1] : data[5],
                data[6],
                data[7],
                content[2] ? content[2] : data[8],
            ];
        } else if (data.length >= 10) {
            result = [
                ...data.slice(0, 3),
                content[0] ? content[0] : data[3],
                data[4],
                content[1] ? content[1] : data[5],
                data[6],
                data[7],
                content[2] ? content[2] : data[8],
                data[9],
            ];
        }
    }

    result = result.filter(item => item);

    for (const item of result) {
        if (!item) {
            const e = new Error();

            e.message += `result = ${JSON.stringify(result)}
             ==========
             content = ${JSON.stringify(content)} 
             ==========
             data = ${JSON.stringify(data)}`;

            if (window && window.BJ_REPORT) window.BJ_REPORT.report(e, false, 'data');
        }
    }

    return result;
};
