import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { ContentShare } from '@ifeng-fe/ui_pc_share';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Caption extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    retain2 = (num, d) => {
        const toString = num.toString();
        const toNumber = parseInt(num);
        let result = toString;

        if (toNumber && toString && !toString.includes('.') && toString.length >= 5) {
            result = `${(parseInt((toNumber / 10000) * 100) / 100).toFixed(d)}万`;
        }

        return result;
    };

    render() {
        const { content } = this.props;
        const {
            type,
            allowComment,
            joinNum,
            count,
            noffhFlag,
            title,
            dateTime,
            fhhAccountDetail,
            author,
            searchPath,
            source,
            sourceUrl,
            summery,
            imagesInContent,
            pcUrl,
            followUrl,
            hasCopyRight,
            faceUrl,
            vestAccountDetail,
            subscribe = {},
            interact,
        } = content;

        const shareInfo = {
            url: pcUrl,
            title,
            summery,
            picUrl: imagesInContent && imagesInContent.length > 0 ? imagesInContent[0].url : '',
        };
        const authorUrl = !subscribe.forbidJump && subscribe.authorUrl ? subscribe.authorUrl : 'javascript:;';
        const isShowFollowButton = subscribe?.forbidFollow === 0;

        return (
            <div>
                {type !== 'short' && <h1 className={styles.topic}>{title}</h1>}
                <div className={styles.info}>
                    {/* 自媒体和非自媒体逻辑结构判断 */}
                    <div className={styles.infoboxLink}>
                        {subscribe.logo && (
                            <div className={styles.face}>
                                <a href={authorUrl}>
                                    <img className={styles.faceImg} src={subscribe.logo} />
                                    {subscribe.honorImg && <img src={subscribe.honorImg} className={styles.faceV} />}
                                </a>
                            </div>
                        )}
                        <div className={styles.textTitle}>
                            <div className={styles.sourceTitle}>
                                <div className={styles.sourceTitleText}>
                                    <a href={authorUrl}>{subscribe.catename}</a>
                                </div>
                                {hasCopyRight &&
                                    isShowFollowButton && (
                                        <div className={styles.follow}>
                                            <img
                                                className={styles.followPicBtn}
                                                src="https://x0.ifengimg.com/ucms/2022_05/F0CA5364C2EE44D3C30EB63ED29990CDE86D9D3F_size3_w100_h40.png"
                                                alt=""
                                            />
                                            <div className={styles.followBox}>
                                                <img
                                                    className={styles.followPic}
                                                    src="https://x0.ifengimg.com/ucms/2022_05/20B903E4FDFBB2BFE6240FC545BD87FBA9243DC1_size26_w1000_h1000.png"
                                                />
                                                <div className={styles.followText}>
                                                    下载客户端<br />独家抢先看
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div className={styles.timeBref}>
                                <a href={authorUrl}>
                                    {dateTime} {subscribe?.lastArticleAddress || ''}
                                </a>
                            </div>
                        </div>
                    </div>
                    {!interact?.isCloseShare && (
                        <div className={styles.share_box}>
                            <ContentShare shareInfo={shareInfo} />
                        </div>
                    )}
                    {allowComment ? (
                        <div className={styles.comment_box}>
                            <h5 className={styles.comment}>
                                <a className={styles.joinNum} href={followUrl} target="_blank">
                                    <span className={styles.num}>{this.retain2(joinNum, 2)}</span>
                                    <span className={styles.text}>人参与</span>
                                </a>
                                <a className={styles.commentNum} href={followUrl} target="_blank">
                                    <span className={styles.num}>{this.retain2(count, 2)}</span>
                                    <span className={styles.text}>评论</span>
                                </a>
                            </h5>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className={styles.devide} />
            </div>
        );
    }
}

export default errorBoundary(Caption);
