/* eslint-disable react/prop-types */
import React from 'react';
import styles from './index.css';
import Title from './Tab/Title';
import RightArticle from './RightArticle';
import errorBoundary from '@ifeng-fe/errorBoundary';
// const { formatImage } = require('@ifeng-fe/public_method');

class Assess extends React.PureComponent {
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        // const title = searchPath?.indexOf('5-') === 0 ? '凤凰V现场' : '凰家评测';
        // const url = searchPath?.indexOf('5-') === 0 ? 'https://tech.ifeng.com/vlive/' : 'http://tech.ifeng.com/lab/';

        return (
            <div className={styles.assess}>
                <Title title="凤凰V现场" url="https://tech.ifeng.com/vlive/" />
                <RightArticle content={content} url="https://tech.ifeng.com/vlive/" />
            </div>
        );
    }
}

export default errorBoundary(Assess);
