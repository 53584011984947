import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class PageList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    getPageList = (limit, current) => {
        const list = [];
        const baseUrl = window.location.href.split(`_${current}`)[0];

        for (let i = 0; i < limit; i++) {
            list.push(
                <a
                    key={i}
                    href={`${baseUrl}_${i}`}
                    className={i === current || (current > limit - 1 && i === limit - 1) ? styles.current : ''}>
                    {current > limit - 1 ? current - 8 + i : i + 1}
                </a>,
            );
        }

        return list;
    };

    render() {
        const { content } = this.props;

        return content.pageSize > 1 ? (
            <div className={styles.page_list}>{this.getPageList(content.pageSize, content.currentPage)}</div>
        ) : (
            ''
        );
    }
}

export default errorBoundary(PageList);
